.ant-notification-top {
	left: 50%;
	transform: translate(-50%, 0);
	right: unset !important;
	bottom: unset !important;
	top: 20px !important;
	margin-right: unset !important;
	.ant-notification-notice-message {
		padding: 3px 0;
		margin-bottom: 0;
	}
}

@primary-color: #0a0523;@secondary-color: #6C5DD3;@ui-red: #E53E3E;@ui-black: #111010;