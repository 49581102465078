@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.tiny-spinner {
	animation: spin 2s linear infinite;
	margin: 0 auto;
	width: 19px;
	height: 19px;
	color: var(--blue-color-multimode);
}

.animated-loading {
	position: relative;
	color: var(--blue-color-multimode);
	font-weight: bold !important;
	font-family: 'Satoshi', monospace !important;
	letter-spacing: 4px !important;
	overflow: hidden;
	border-right: 2px solid hsl(0, 0%, 80%);
	white-space: nowrap;
	animation: typewriter 0.5s steps(100) 0s 1 normal both;
	transition: 1s;
	margin-top: 20px;
}

@keyframes typewriter {
	from {
		width: 0;
	}
	to {
		width: 100px;
	}
}
@keyframes blinkTextCursor {
	from {
		border-right-color: hsl(0, 0%, 80%);
	}
	to {
		border-right-color: transparent;
	}
}

.loader-animated {
	.center {
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
	}
	.wave {
		width: 2px;
		height: 50px;
		background: linear-gradient(45deg, cyan, #fff);
		margin: 10px;
		animation: wave 1s linear infinite;
		border-radius: 20px;
	}
	.wave:nth-child(2) {
		animation-delay: 0.1s;
	}
	.wave:nth-child(3) {
		animation-delay: 0.2s;
	}
	.wave:nth-child(4) {
		animation-delay: 0.3s;
	}
	.wave:nth-child(5) {
		animation-delay: 0.4s;
	}
	.wave:nth-child(6) {
		animation-delay: 0.5s;
	}
	.wave:nth-child(7) {
		animation-delay: 0.6s;
	}
	.wave:nth-child(8) {
		animation-delay: 0.7s;
	}
	.wave:nth-child(9) {
		animation-delay: 0.8s;
	}
	.wave:nth-child(10) {
		animation-delay: 0.9s;
	}

	@keyframes wave {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
}


@primary-color: #0a0523;@secondary-color: #6C5DD3;@ui-red: #E53E3E;@ui-black: #111010;