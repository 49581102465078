@import url('./__variables.less');

.container {
	margin: 0;
	padding: 0;
	width: 100%;
	min-height: 100vh;
	display: flex;
	background-color: var(--container-color);
}

.align-center {
	display: flex;
	align-items: center;
}

.all-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup-input-border {
	border: 1px solid var(--popup-input-border-color) !important;
	border-radius: 2px;
}

.popup-input-suffix-border {
	border: 2px solid #2b2a2a !important;
	border-radius: 2px;
}

.gradiental-border {
	border-width: 1px;
	border-style: solid;
	border-image: linear-gradient(to right, var(--line-divider), rgb(255, 255, 255)) 1;
}

.gradiental-border-darkmode {
	border-width: 1px;
	border-style: solid;
	border-image: linear-gradient(to left, var(--line-divider), rgb(82, 79, 79)) 1;
	border-top: none;
	border-left: none;
	border-right: none;
}

.border-top-radius(@x) {
	border-top-left-radius: @x;
	border-top-right-radius: @x;
}

.border-bottom-radius(@x) {
	border-bottom-left-radius: @x;
	border-bottom-right-radius: @x;
}

.ant-input-form-style {
	.ant-input {
		background-color: var(--login-popup-input-bg-color);
		height: 32px;
		color: var(--common-reverse-color);
		font-family: 'Satoshi';
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 32px !important;
		.align-center() !important;
		.popup-input-border();
	}

	.ant-input::placeholder {
		color: var(--common-reverse-color);
	}
}

.ant-tab-styles {
	.ant-tabs-nav {
		margin: 0;
	}
	.ant-tabs-nav::before {
		display: none !important;
	}
	.ant-tabs-tab {
		margin: 0 !important;
		padding: 3px 5px !important;
	}
	.ant-tabs-nav-list {
		gap: 10px;
	}
	.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: var(--ant-navbar-active-key-color) !important;
		}
	}
	.ant-tabs-tab-btn {
		font-family: 'Satoshi';
		font-style: normal;
		font-weight: 600;
		font-size: 11px !important;
		line-height: 15px;
		color: var(--ant-tab--btn-color);
		text-transform: uppercase;
	}
}

.bet-box-header-styles {
	width: 100%;
	background: var(--sport-bet-box-info-bg-color);
	.align-center();
	justify-content: space-between;
    padding: 5px 10px 2px 10px;
	border-radius: 4px 4px 0 0;
}

.gradiental-line-divider {
	width: 100%;
	height: 1px;
	background: linear-gradient(134deg, #262626 0%, rgba(38, 38, 38, 0) 100%);
}

.ant-input-prefix-styles {
	.ant-input-number-affix-wrapper {
		width: 45%;
		background-color: var(--login-popup-input-bg-color);
		.popup-input-border();
	}
	.ant-input-number {
		background-color: transparent;
		.align-center();
	}

	.ant-input-number-input,
	.ant-input-number-prefix {
		color: var(--common-reverse-color) !important;
		font-family: 'Satoshi';
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 32px !important;
	}
}

.ant-input-suffix-wrapper {
	.ant-input-affix-wrapper {
		background-color: var(--login-popup-input-bg-color);
		.popup-input-border();
		padding: 0;
		padding-left: 5px;
		.ant-input-affix-wrapper-focused {
			box-shadow: 0 0 0 1px red !important;
		}
	}
	.ant-input {
		.align-center();
		border: none !important;
		padding-left: 5px;
	}

	.ant-input,
	.ant-input-suffix {
		color: var(--common-reverse-color) !important;
		font-family: 'Satoshi';
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 32px !important;
		margin-right: 5px;
	}
	.ant-input-suffix {
		cursor: pointer;
	}
}

.ant-input-affix-styles {
	.ant-input-affix-wrapper {
		width: 45%;
		background-color: var(--login-popup-input-bg-color);
		.popup-input-border();
		padding: 0;
		padding-left: 5px;
		transition: none;
		height: 28px;
	}
	.ant-input,
	.ant-input-prefix {
		color: var(--common-reverse-color) !important;
		font-family: 'Satoshi';
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 32px !important;
		margin-right: 5px;
		background-color: var(--login-popup-input-bg-color);
		transition: none;
	}
	.ant-input-affix-wrapper-focused {
		box-shadow: 0 0 0 1px var(--common-blue-color) !important;
		border: none !important;
		outline: none;
	}
	.ant-input-affix-wrapper-status-error {
		box-shadow: 0 0 0 2px #dc1226 !important;
		border: none !important;
	}
}

.ellipsis-text(@width) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: @width;
	width: fit-content;
}

.box-shadow-styles {
	// filter: drop-shadow(
	// 	var(--filter-shadow-arg1),
	// 	var(--filter-shadow-arg2),
	// 	var(--filter-shadow-arg3),
	// 	var(--filter-shadow-color)
	// );
	// filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
	box-shadow: 0px 0px 2px rgb(0 0 0 / 40%);
}
