:root {
  --coefficent-box-width: 60px;
}
@media screen and (max-width: 430px) {
  :root {
    --coefficent-box-width: 60px;
    /* New value for screens <= 768px */
  }
}
:root {
  --betslip-width: 300px;
  --left-sider-width: 196px;
  --numpad-height: 160px;
  --doc-height: 100%;
}
:root {
  --live-icon-inactive-color: #fff;
  --live-icon-inactive-bg-color: #9da0a6;
  --live-menu-collapse-header-bg-color: #ebebeb;
  --live-menu-collapse-header-color: #000000;
  --container-menu-color: #fdfdfd;
  --live-menu-collapse-item-bg-color: #fdfdfd;
  --live-menu-collapse-item-color: #000000;
  --live-menu-border-color: #c6c6c6;
  --container-color: #f2f2f2;
  --background-color: #0c1d29;
  --sport-select-color: #112633;
  --header-color: #1c1c1e;
  --footer-text-color: #606060;
  --line-divider: #ececec;
  --line-divider-dark: #252525;
  --line-divider-footer: #e2e2e2;
  --header-down-text-color: #cacaca;
  --login-input-field-bg: #2a2a2a;
  --common-reverse-color: #000;
  --ant-primary-button-text-color: #fff;
  --header-routes-color: #fff;
  --betslip-nested-routes: #0a0a0a;
  --odds-action-hint-text-color: #c7cbcf;
  --betslip-container-color: #f1f2f4;
  --betslip-amount-counter-text: #000;
  --betslip-login-hint-button-bg-color: #72777f;
  --login-popup-input-bg-color: #fbfbfb;
  --login-popup-bg-color: #1c1c1e;
  --login-popup-hint-text-color-regular: #c9d1db;
  --login-popup-hint-text-color-bold: #f0f6fe;
  --menu-sport-select-text-color: #efefef;
  --common-blue-color: #005fc8;
  --single-bet-row-title: #6d6d6d;
  --single-bet-header-background-color: #1c1c1e;
  --single-bet-header-border-color: #262626;
  --single-bet-coefficent-box-background-color: #fff;
  --single-bet-row-background-color: #161616;
  --user-balance-dropdown-background-color: #fff;
  --user-bets-sider-menu-text-color: #000;
  --gradient-start-color: #1d1c1c;
  --unavailable-bet-box-wrapper-background-color: #efefef;
  --single-bet-page-header-background-color: #fdfdfd;
  --svg-icon-color-sider-menu: #070606;
  --svg-icon-color-sider-menu-active: #000;
  --svg-icon-swiper: #868686;
  --svg-icon-swiper-active: #ebeff4;
  --blue-color-multimode: #005fc8;
  --hint-header-color: #f9f9f9;
  --text-area-background-color: #0d0d0d;
  --custom-numpad-key-bg-color: #c9d1db;
  --custom-numpad-key-color: #000;
  --betslip-background-color: #f2f2f2;
  --drawer-body-color: #040404;
  --bets-wrapper-background-color: #f1f1f1;
  --betslip-handler-background-color: #f1f1f1;
  --sider-menu-background-color: #f2f2f2;
  --case-component-background-color: #fdfdfd;
  --betslip-header-tabs-background-color: #191919;
  --events-swiper-background-color: #fdfdfd;
  --slider-button-background-color: #fdfdfd;
  --slider-item-sportname-color: #000;
  --footer-background-color: #161616;
  --match-counter-background-color: #fff;
  --navigation-links-background-color: #fff;
  --grid-template-background-color: #fdfdfd;
  --nearby-games-hint-background-color: #fdfdfd;
  --game-location-background-color: #fdfdfd;
  --user-balance-background-color: #2a2a2e;
  --deposit-select-background-color: #2a2a2a;
  --bet-box-header-background-color: #fff;
  --login-hint-button-message-color: #fff;
  --popup-header-color: #fff;
  --popup-input-color: #fff;
  --popup-select-color: #fff;
  --checkbox-label-color: #fff;
  --coefficent-color: #fff;
  --sport-rule-text-area: #fff;
  --verification-box-row-wrapper: #fff;
  --bet-type-picker-selection-color: #fff;
  --inbox-input-color: #fff;
  --reset-password-color: #fff;
  --input-row-field-color: #fff;
  --balance-color: #fff;
  --sport-betting-test-color: #fff;
  --acount-status-color: #fff;
  --odds-handle-panel-background-color: #f6f5f5;
  --footer-privacy-policy-color: #cacaca;
  --header-login-color: #cacaca;
  --searchbar-input-color: #cacaca;
  --login-popup-select-bg-color: #0a0a0a;
  --inbox-content-input-bg-color: #0a0a0a;
  --reset-password-input-bg-color: #0a0a0a;
  --input-row-field-input-bg-color: #0a0a0a;
  --input-row-field-select-bg-color: #0a0a0a;
  --rules-search-input-bg-color: #161616;
  --rules-search-input-color: #161616;
  --deposit-address-hint-color: #c9d1db;
  --qr-code-hint-color: #c9d1db;
  --live-overview-match-counter: #000;
  --betting-side-header-title-color: #efefef;
  --single-league-bets-collapse-header-color: #000;
  --case-component-collapse-header-title-color: #515151;
  --special-fixture-cutoff-color: #868686;
  --prediction-info-indicator-color: #474747;
  --result-info-wrapper-hint-color: #868686;
  --bet-history-connection-type: #868686;
  --bet-history-id-hint: #868686;
  --footer-selector-label-color: #868686;
  --footer-select-color: #868686;
  --mobile-footer-gambling-hint-color: #868686;
  --mobile-footer-privacy-policy-color: #868686;
  --ant-tab--btn-color: #868686;
  --searched-component-date-color: #868686;
  --live-game-timer-hint-color: #868686;
  --live-overview-header-title: #6d6d6d;
  --single-bet-row-container-color: #868686;
  --single-bet-row-growth-number: #868686;
  --single-bet-markets-counter: #868686;
  --time-table-event-date-color: #868686;
  --account-status-hint-wrapper: #868686;
  --client-banner-clientId-color: #868686;
  --client-banner-balance-color: #868686;
  --bet-history-info-thin: #868686;
  --game-status-hint: #868686;
  --bet-type-picker-select-border-color: #868686;
  --inbox-header-date-color: #868686;
  --verification-page-ant-form-req-color: #868686;
  --bet-history-header-bg-color: #1c1c1e;
  --filtered-element-bg-color: #fdfdfd;
  --bet-history-bg-picker-color: #1c1c1e;
  --user-bets-hint-header-bg-color: #1c1c1e;
  --client-banner-bg-color: #1c1c1e;
  --client-manager-bg-color: #1c1c1e;
  --deposit-page-wrapper-div: #2a2a2e;
  --bet-type-picker-select-bg-color: #1c1c1e;
  --verification-form-bg-color: #1c1c1e;
  --bet-collapse-header-border: #e1e1e1;
  --time-table-event-border: #c9d1db;
  --single-league-border: #c9d1db;
  --filtered-element-border: #c9d1db;
  --live-game-row-border: #f1f1f1;
  --grid-template-border: #ececec;
  --hint-wrapper-border: #262626;
  --slider-item-bg-color: #fbfbfb;
  --odd-row-bg-color: #fff;
  --single-bet-sport-info-color: #000;
  --ant-collapse-header-color: #fdfdfd;
  --odd-row-color: #000;
  --bet-box-club-name-color: #000;
  --bet-box-close-btn: #ebeff4;
  --date-text-info-color: #000;
  --prediction-info-color: #000;
  --gaining-counter-hint: #000;
  --bet-type-handler-label: #000;
  --result-info-hint-color: #ebeff4;
  --bet-hint-color: #ebeff4;
  --navigator-hint-color: #ebeff4;
  --withdraw-deposit-color: #ebeff4;
  --leagues-helmet-color: #000;
  --single-league-color: #000;
  --fixture-container-color: #000;
  --result-counter-text: #000;
  --live-panel-header: #000;
  --participant-name-color: #000;
  --condtiotions-header-color: #ebeff4;
  --navigation-menu-title-color: #ebeff4;
  --verification-type-title-color: #ebeff4;
  --bet-history-header-title: #ebeff4;
  --bet-history-picker-color: #ebeff4;
  --client-info-color: #ebeff4;
  --mobile-personal-page-menu-color: #ebeff4;
  --bet-history-game-info-color: #ebeff4;
  --inbox-header-title: #ebeff4;
  --password-and-security-label-color: #ebeff4;
  --verification-form-color: #ebeff4;
  --single-league-wrapper-background-color: #fbfbfb;
  --searchPage-header-down-bg-color: #fff;
  --current-league-hint-background-color: #fff;
  --numpad-key-background-color: #fdfdfd;
  --bets-total-background-color: #fff;
  --sport-bet-box-info-bg-color: #fff;
  --gaining-counter-wrapper-bg-color: #fff;
  --bet-type-handler-button-bg-color: #fff;
  --result-info-wrapper-bg-color: #1c1c1e;
  --popup-betslip-wrapper-bg-color: #1c1c1e;
  --all-leagues-helmet-bg-color: #f9f9f9;
  --filtered-elements-page-header-wrapper-bg-color: #f9f9f9;
  --result-wrapper-bg-color: #f9f9f9;
  --live-game-row-wrapper-bg-color: #fdfdfd;
  --single-league-bets-content-box-bg-color: #fff;
  --nearby-games-bg-color: #1c1c1e;
  --modal-page-wrapper-bg-color: #1c1c1e;
  --bet-history-wrapper-header-bg-color: #1c1c1e;
  --bet-history-collapse-header-bg-color: #1c1c1e;
  --deposit-nested-wrapper-bg-color: #1c1c1e;
  --mobile-personal-details-menu-bg-color: #1c1c1e;
  --password-and-security-bg-color: #1c1c1e;
  --single-transaction-bg-color: #1c1c1e;
  --verification-page-bg-color: #1c1c1e;
  --betslip-tab-text-color: #3d3d3d;
  --multiple-bets-text-info-color: #000;
  --mobile-footer-links-color: #868686;
  --login-field-placeholder-color: #cacaca;
  --balance-wrapper-text-title: #979797;
  --header-down-route-color: #fff;
  --custom-label-sportcount-color: #fff;
  --ant-drawer-bg-color: #f1f1f1;
  --popup-submit-button-color: #fff;
  --login-field-hint-color: #132a3a;
  --page-header-title-color: #000;
  --single-bet-coefficent-box-outline-color: #ececec;
  --coefficent-box-diff-color: #000;
  --bet-coefficent-template-hover-color: #fff;
  --ant-navbar-active-key-color: #000;
  --menu-item-hover-color: #fff;
  --current-hint-league-color: #000;
  --bet-history-collapse-wrapper-bg-color: #f8f8f8;
  --not-found-page-title-color: #000;
  --slider-item-border-color: #ececec;
  --rate-text-info-color: #ebeff4;
  --filter-shadow-color: rgba(0, 0, 0, 0.2);
  --filter-shadow-arg1: 0px;
  --filter-shadow-arg2: 1px;
  --filter-shadow-arg3: 2px;
  --coefficent-box-wrapper-suspended-color: #9b9b9b;
  --in-play-hint-text-color: #111;
}
[data-theme='dark'] {
  --live-icon-inactive-color: #64646f;
  --live-icon-inactive-bg-color: #2c2c2f;
  --live-menu-collapse-header-bg-color: #2a2a2e;
  --live-menu-collapse-header-color: #ffffff;
  --container-menu-color: #090909;
  --live-menu-collapse-item-bg-color: #1c1c1e;
  --live-menu-border-color: #39393b;
  --container-color: #090909;
  --background-color: #0c1d29;
  --sport-select-color: #112633;
  --header-color: #1c1c1e;
  --footer-text-color: #c7cbcf;
  --line-divider: #2a2a2e;
  --line-divider-footer: #1a1a1a;
  --header-down-text-color: #cacaca;
  --login-input-field-bg: #2a2a2a;
  --common-reverse-color: #fff;
  --ant-primary-button-text-color: #fff;
  --header-routes-color: #fff;
  --betslip-nested-routes: #0a0a0a;
  --odds-action-hint-text-color: #c7cbcf;
  --betslip-container-color: #080808;
  --betslip-amount-counter-text: #cacaca;
  --betslip-login-hint-button-bg-color: #72777f;
  --login-popup-input-bg-color: #0a0a0a;
  --login-popup-bg-color: #1c1c1e;
  --login-popup-hint-text-color-regular: #c9d1db;
  --login-popup-hint-text-color-bold: #f0f6fe;
  --menu-sport-select-text-color: #efefef;
  --common-blue-color: #2b90ff;
  --single-bet-row-title: #adadad;
  --single-bet-header-background-color: #1c1c1e;
  --single-bet-header-border-color: #262626;
  --single-bet-coefficent-box-background-color: #000;
  --single-bet-row-background-color: #161616;
  --user-balance-dropdown-background-color: #fff;
  --user-bets-sider-menu-text-color: #ebeff4;
  --gradient-start-color: #1d1c1c;
  --unavailable-bet-box-wrapper-background-color: #232326;
  --single-bet-page-header-background-color: #1c1c1e;
  --svg-icon-color-sider-menu: #ebeff4;
  --svg-icon-color-sider-menu-active: #a2e9ff;
  --svg-icon-swiper: #868686;
  --svg-icon-swiper-active: #ebeff4;
  --blue-color-multimode: #2b90ff;
  --hint-header-color: #2a2a2e;
  --text-area-background-color: #0d0d0d;
  --custom-numpad-key-bg-color: #040404;
  --custom-numpad-key-color: #ebeff4;
  --betslip-background-color: #101010;
  --drawer-body-color: #040404;
  --bets-wrapper-background-color: #000000;
  --betslip-handler-background-color: #000000;
  --sider-menu-background-color: #000;
  --case-component-background-color: #1c1c1e;
  --betslip-header-tabs-background-color: #1c1c1e;
  --events-swiper-background-color: #1c1c1e;
  --slider-button-background-color: #1c1c1e;
  --slider-item-sportname-color: #ebeff4;
  --footer-background-color: #161616;
  --match-counter-background-color: #2a2a2e;
  --navigation-links-background-color: #1c1c1e;
  --grid-template-background-color: #1c1c1e;
  --nearby-games-hint-background-color: #1c1c1e;
  --game-location-background-color: #1c1c1e;
  --user-balance-background-color: #2a2a2e;
  --deposit-select-background-color: #2a2a2a;
  --bet-box-header-background-color: #1c1c1e;
  --login-hint-button-message-color: #fff;
  --popup-header-color: #fff;
  --popup-input-color: #fff;
  --popup-select-color: #fff;
  --checkbox-label-color: #fff;
  --coefficent-color: #fff;
  --sport-rule-text-area: #fff;
  --verification-box-row-wrapper: #fff;
  --bet-type-picker-selection-color: #fff;
  --inbox-input-color: #fff;
  --reset-password-color: #fff;
  --input-row-field-color: #fff;
  --balance-color: #fff;
  --sport-betting-test-color: #fff;
  --acount-status-color: #fff;
  --odds-handle-panel-background-color: #060606;
  --footer-privacy-policy-color: #cacaca;
  --header-login-color: #cacaca;
  --searchbar-input-color: #cacaca;
  --login-popup-select-bg-color: #0a0a0a;
  --inbox-content-input-bg-color: #0a0a0a;
  --reset-password-input-bg-color: #0a0a0a;
  --input-row-field-input-bg-color: #0a0a0a;
  --input-row-field-select-bg-color: #0a0a0a;
  --rules-search-input-bg-color: #161616;
  --rules-search-input-color: #161616;
  --deposit-address-hint-color: #c9d1db;
  --qr-code-hint-color: #c9d1db;
  --live-overview-match-counter: #c9d1db;
  --betting-side-header-title-color: #efefef;
  --single-league-bets-collapse-header-color: #efefef;
  --case-component-collapse-header-title-color: #868686;
  --special-fixture-cutoff-color: #868686;
  --prediction-info-indicator-color: #aeaeae;
  --result-info-wrapper-hint-color: #868686;
  --bet-history-connection-type: #868686;
  --bet-history-id-hint: #868686;
  --footer-selector-label-color: #868686;
  --footer-select-color: #868686;
  --mobile-footer-gambling-hint-color: #868686;
  --mobile-footer-privacy-policy-color: #868686;
  --ant-tab--btn-color: #868686;
  --searched-component-date-color: #868686;
  --live-game-timer-hint-color: #868686;
  --live-overview-header-title: #adadad;
  --single-bet-row-container-color: #868686;
  --single-bet-row-growth-number: #868686;
  --single-bet-markets-counter: #868686;
  --time-table-event-date-color: #868686;
  --account-status-hint-wrapper: #868686;
  --client-banner-clientId-color: #868686;
  --client-banner-balance-color: #868686;
  --bet-history-info-thin: #868686;
  --game-status-hint: #868686;
  --bet-type-picker-select-border-color: #868686;
  --inbox-header-date-color: #868686;
  --verification-page-ant-form-req-color: #868686;
  --bet-history-header-bg-color: #1c1c1e;
  --filtered-element-bg-color: #1c1c1e;
  --bet-history-bg-picker-color: #1c1c1e;
  --user-bets-hint-header-bg-color: #1c1c1e;
  --client-banner-bg-color: #1c1c1e;
  --client-manager-bg-color: #1c1c1e;
  --deposit-page-wrapper-div: #2a2a2e;
  --bet-type-picker-select-bg-color: #1c1c1e;
  --verification-form-bg-color: #1c1c1e;
  --bet-collapse-header-border: #262626;
  --time-table-event-border: #262626;
  --single-league-border: #262626;
  --filtered-element-border: #262626;
  --live-game-row-border: #202020;
  --grid-template-border: #2a2a2e;
  --hint-wrapper-border: #262626;
  --slider-item-bg-color: #2a2a2e;
  --odd-row-bg-color: #040404;
  --single-bet-sport-info-color: #fff;
  --ant-collapse-header-color: #1c1c1e;
  --odd-row-color: #fff;
  --bet-box-club-name-color: #ebeff4;
  --bet-box-close-btn: #ebeff4;
  --date-text-info-color: #ebeff4;
  --prediction-info-color: #ebeff4;
  --gaining-counter-hint: #ebeff4;
  --bet-type-handler-label: #ebeff4;
  --result-info-hint-color: #ebeff4;
  --bet-hint-color: #ebeff4;
  --navigator-hint-color: #ebeff4;
  --withdraw-deposit-color: #ebeff4;
  --leagues-helmet-color: #ebeff4;
  --single-league-color: #ebeff4;
  --fixture-container-color: #ebeff4;
  --result-counter-text: #ebeff4;
  --live-panel-header: #ebeff4;
  --participant-name-color: #ebeff4;
  --condtiotions-header-color: #ebeff4;
  --navigation-menu-title-color: #ebeff4;
  --verification-type-title-color: #ebeff4;
  --bet-history-header-title: #ebeff4;
  --bet-history-picker-color: #ebeff4;
  --client-info-color: #ebeff4;
  --mobile-personal-page-menu-color: #ebeff4;
  --bet-history-game-info-color: #ebeff4;
  --inbox-header-title: #ebeff4;
  --password-and-security-label-color: #ebeff4;
  --verification-form-color: #ebeff4;
  --single-league-wrapper-background-color: #1c1c1e;
  --searchPage-header-down-bg-color: #1c1c1e;
  --current-league-hint-background-color: #1c1c1e;
  --numpad-key-background-color: #1c1c1e;
  --bets-total-background-color: #1c1c1e;
  --sport-bet-box-info-bg-color: #1c1c1e;
  --gaining-counter-wrapper-bg-color: #1c1c1e;
  --bet-type-handler-button-bg-color: #1c1c1e;
  --result-info-wrapper-bg-color: #1c1c1e;
  --popup-betslip-wrapper-bg-color: #1c1c1e;
  --all-leagues-helmet-bg-color: #1c1c1e;
  --filtered-elements-page-header-wrapper-bg-color: #1c1c1e;
  --result-wrapper-bg-color: #1c1c1e;
  --live-game-row-wrapper-bg-color: #1c1c1e;
  --single-league-bets-content-box-bg-color: #1c1c1e;
  --nearby-games-bg-color: #1c1c1e;
  --modal-page-wrapper-bg-color: #1c1c1e;
  --bet-history-wrapper-header-bg-color: #1c1c1e;
  --bet-history-collapse-header-bg-color: #1c1c1e;
  --deposit-nested-wrapper-bg-color: #1c1c1e;
  --mobile-personal-details-menu-bg-color: #1c1c1e;
  --password-and-security-bg-color: #1c1c1e;
  --single-transaction-bg-color: #1c1c1e;
  --verification-page-bg-color: #1c1c1e;
  --betslip-tab-text-color: #3d3d3d;
  --multiple-bets-text-info-color: #ebeff4;
  --mobile-footer-links-color: #868686;
  --login-field-placeholder-color: #cacaca;
  --balance-wrapper-text-title: #979797;
  --header-down-route-color: #fff;
  --custom-label-sportcount-color: #fff;
  --ant-drawer-bg-color: #1c1c1e;
  --popup-submit-button-color: #fff;
  --login-field-hint-color: #132a3a;
  --page-header-title-color: #ebeff4;
  --single-bet-coefficent-box-outline-color: transparent;
  --coefficent-box-diff-color: #fff;
  --bet-coefficent-template-hover-color: #1f1f20;
  --ant-navbar-active-key-color: #fff;
  --menu-item-hover-color: #27272a;
  --current-hint-league-color: #ebeff4;
  --bet-history-collapse-wrapper-bg-color: #060606;
  --not-found-page-title-color: #fff;
  --slider-item-border-color: #242424;
  --rate-text-info-color: #ebeff4;
  --filter-shadow-color: transparent;
  --filter-shadow-arg1: 0px;
  --filter-shadow-arg2: 0px;
  --filter-shadow-arg3: 0px;
  --coefficent-box-wrapper-suspended-color: #72777f;
  --in-play-hint-text-color: #ebeff4;
  --popup-input-border-color: #2b2a2a;
}
.container {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: var(--container-color);
}
.align-center {
  display: flex;
  align-items: center;
}
.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-input-border {
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
}
.popup-input-suffix-border {
  border: 2px solid #2b2a2a !important;
  border-radius: 2px;
}
.gradiental-border {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, var(--line-divider), #ffffff) 1;
}
.gradiental-border-darkmode {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to left, var(--line-divider), #524f4f) 1;
  border-top: none;
  border-left: none;
  border-right: none;
}
.ant-input-form-style .ant-input {
  background-color: var(--login-popup-input-bg-color);
  height: 32px;
  color: var(--common-reverse-color);
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px !important;
  display: flex !important;
  align-items: center !important;
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
}
.ant-input-form-style .ant-input::placeholder {
  color: var(--common-reverse-color);
}
.ant-tab-styles .ant-tabs-nav {
  margin: 0;
}
.ant-tab-styles .ant-tabs-nav::before {
  display: none !important;
}
.ant-tab-styles .ant-tabs-tab {
  margin: 0 !important;
  padding: 3px 5px !important;
}
.ant-tab-styles .ant-tabs-nav-list {
  gap: 10px;
}
.ant-tab-styles .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--ant-navbar-active-key-color) !important;
}
.ant-tab-styles .ant-tabs-tab-btn {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 11px !important;
  line-height: 15px;
  color: var(--ant-tab--btn-color);
  text-transform: uppercase;
}
.bet-box-header-styles {
  width: 100%;
  background: var(--sport-bet-box-info-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 2px 10px;
  border-radius: 4px 4px 0 0;
}
.gradiental-line-divider {
  width: 100%;
  height: 1px;
  background: linear-gradient(134deg, #262626 0%, rgba(38, 38, 38, 0) 100%);
}
.ant-input-prefix-styles .ant-input-number-affix-wrapper {
  width: 45%;
  background-color: var(--login-popup-input-bg-color);
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
}
.ant-input-prefix-styles .ant-input-number {
  background-color: transparent;
  display: flex;
  align-items: center;
}
.ant-input-prefix-styles .ant-input-number-input,
.ant-input-prefix-styles .ant-input-number-prefix {
  color: var(--common-reverse-color) !important;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px !important;
}
.ant-input-suffix-wrapper .ant-input-affix-wrapper {
  background-color: var(--login-popup-input-bg-color);
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
  padding: 0;
  padding-left: 5px;
}
.ant-input-suffix-wrapper .ant-input-affix-wrapper .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px red !important;
}
.ant-input-suffix-wrapper .ant-input {
  display: flex;
  align-items: center;
  border: none !important;
  padding-left: 5px;
}
.ant-input-suffix-wrapper .ant-input,
.ant-input-suffix-wrapper .ant-input-suffix {
  color: var(--common-reverse-color) !important;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px !important;
  margin-right: 5px;
}
.ant-input-suffix-wrapper .ant-input-suffix {
  cursor: pointer;
}
.ant-input-affix-styles .ant-input-affix-wrapper {
  width: 45%;
  background-color: var(--login-popup-input-bg-color);
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
  padding: 0;
  padding-left: 5px;
  transition: none;
  height: 28px;
}
.ant-input-affix-styles .ant-input,
.ant-input-affix-styles .ant-input-prefix {
  color: var(--common-reverse-color) !important;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px !important;
  margin-right: 5px;
  background-color: var(--login-popup-input-bg-color);
  transition: none;
}
.ant-input-affix-styles .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px var(--common-blue-color) !important;
  border: none !important;
  outline: none;
}
.ant-input-affix-styles .ant-input-affix-wrapper-status-error {
  box-shadow: 0 0 0 2px #dc1226 !important;
  border: none !important;
}
.box-shadow-styles {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}
:root {
  --coefficent-box-width: 60px;
}
@media screen and (max-width: 430px) {
  :root {
    --coefficent-box-width: 60px;
    /* New value for screens <= 768px */
  }
}
:root {
  --betslip-width: 300px;
  --left-sider-width: 196px;
  --numpad-height: 160px;
  --doc-height: 100%;
}
:root {
  --live-icon-inactive-color: #fff;
  --live-icon-inactive-bg-color: #9da0a6;
  --live-menu-collapse-header-bg-color: #ebebeb;
  --live-menu-collapse-header-color: #000000;
  --container-menu-color: #fdfdfd;
  --live-menu-collapse-item-bg-color: #fdfdfd;
  --live-menu-collapse-item-color: #000000;
  --live-menu-border-color: #c6c6c6;
  --container-color: #f2f2f2;
  --background-color: #0c1d29;
  --sport-select-color: #112633;
  --header-color: #1c1c1e;
  --footer-text-color: #606060;
  --line-divider: #ececec;
  --line-divider-dark: #252525;
  --line-divider-footer: #e2e2e2;
  --header-down-text-color: #cacaca;
  --login-input-field-bg: #2a2a2a;
  --common-reverse-color: #000;
  --ant-primary-button-text-color: #fff;
  --header-routes-color: #fff;
  --betslip-nested-routes: #0a0a0a;
  --odds-action-hint-text-color: #c7cbcf;
  --betslip-container-color: #f1f2f4;
  --betslip-amount-counter-text: #000;
  --betslip-login-hint-button-bg-color: #72777f;
  --login-popup-input-bg-color: #fbfbfb;
  --login-popup-bg-color: #1c1c1e;
  --login-popup-hint-text-color-regular: #c9d1db;
  --login-popup-hint-text-color-bold: #f0f6fe;
  --menu-sport-select-text-color: #efefef;
  --common-blue-color: #005fc8;
  --single-bet-row-title: #6d6d6d;
  --single-bet-header-background-color: #1c1c1e;
  --single-bet-header-border-color: #262626;
  --single-bet-coefficent-box-background-color: #fff;
  --single-bet-row-background-color: #161616;
  --user-balance-dropdown-background-color: #fff;
  --user-bets-sider-menu-text-color: #000;
  --gradient-start-color: #1d1c1c;
  --unavailable-bet-box-wrapper-background-color: #efefef;
  --single-bet-page-header-background-color: #fdfdfd;
  --svg-icon-color-sider-menu: #070606;
  --svg-icon-color-sider-menu-active: #000;
  --svg-icon-swiper: #868686;
  --svg-icon-swiper-active: #ebeff4;
  --blue-color-multimode: #005fc8;
  --hint-header-color: #f9f9f9;
  --text-area-background-color: #0d0d0d;
  --custom-numpad-key-bg-color: #c9d1db;
  --custom-numpad-key-color: #000;
  --betslip-background-color: #f2f2f2;
  --drawer-body-color: #040404;
  --bets-wrapper-background-color: #f1f1f1;
  --betslip-handler-background-color: #f1f1f1;
  --sider-menu-background-color: #f2f2f2;
  --case-component-background-color: #fdfdfd;
  --betslip-header-tabs-background-color: #191919;
  --events-swiper-background-color: #fdfdfd;
  --slider-button-background-color: #fdfdfd;
  --slider-item-sportname-color: #000;
  --footer-background-color: #161616;
  --match-counter-background-color: #fff;
  --navigation-links-background-color: #fff;
  --grid-template-background-color: #fdfdfd;
  --nearby-games-hint-background-color: #fdfdfd;
  --game-location-background-color: #fdfdfd;
  --user-balance-background-color: #2a2a2e;
  --deposit-select-background-color: #2a2a2a;
  --bet-box-header-background-color: #fff;
  --login-hint-button-message-color: #fff;
  --popup-header-color: #fff;
  --popup-input-color: #fff;
  --popup-select-color: #fff;
  --checkbox-label-color: #fff;
  --coefficent-color: #fff;
  --sport-rule-text-area: #fff;
  --verification-box-row-wrapper: #fff;
  --bet-type-picker-selection-color: #fff;
  --inbox-input-color: #fff;
  --reset-password-color: #fff;
  --input-row-field-color: #fff;
  --balance-color: #fff;
  --sport-betting-test-color: #fff;
  --acount-status-color: #fff;
  --odds-handle-panel-background-color: #f6f5f5;
  --footer-privacy-policy-color: #cacaca;
  --header-login-color: #cacaca;
  --searchbar-input-color: #cacaca;
  --login-popup-select-bg-color: #0a0a0a;
  --inbox-content-input-bg-color: #0a0a0a;
  --reset-password-input-bg-color: #0a0a0a;
  --input-row-field-input-bg-color: #0a0a0a;
  --input-row-field-select-bg-color: #0a0a0a;
  --rules-search-input-bg-color: #161616;
  --rules-search-input-color: #161616;
  --deposit-address-hint-color: #c9d1db;
  --qr-code-hint-color: #c9d1db;
  --live-overview-match-counter: #000;
  --betting-side-header-title-color: #efefef;
  --single-league-bets-collapse-header-color: #000;
  --case-component-collapse-header-title-color: #515151;
  --special-fixture-cutoff-color: #868686;
  --prediction-info-indicator-color: #474747;
  --result-info-wrapper-hint-color: #868686;
  --bet-history-connection-type: #868686;
  --bet-history-id-hint: #868686;
  --footer-selector-label-color: #868686;
  --footer-select-color: #868686;
  --mobile-footer-gambling-hint-color: #868686;
  --mobile-footer-privacy-policy-color: #868686;
  --ant-tab--btn-color: #868686;
  --searched-component-date-color: #868686;
  --live-game-timer-hint-color: #868686;
  --live-overview-header-title: #6d6d6d;
  --single-bet-row-container-color: #868686;
  --single-bet-row-growth-number: #868686;
  --single-bet-markets-counter: #868686;
  --time-table-event-date-color: #868686;
  --account-status-hint-wrapper: #868686;
  --client-banner-clientId-color: #868686;
  --client-banner-balance-color: #868686;
  --bet-history-info-thin: #868686;
  --game-status-hint: #868686;
  --bet-type-picker-select-border-color: #868686;
  --inbox-header-date-color: #868686;
  --verification-page-ant-form-req-color: #868686;
  --bet-history-header-bg-color: #1c1c1e;
  --filtered-element-bg-color: #fdfdfd;
  --bet-history-bg-picker-color: #1c1c1e;
  --user-bets-hint-header-bg-color: #1c1c1e;
  --client-banner-bg-color: #1c1c1e;
  --client-manager-bg-color: #1c1c1e;
  --deposit-page-wrapper-div: #2a2a2e;
  --bet-type-picker-select-bg-color: #1c1c1e;
  --verification-form-bg-color: #1c1c1e;
  --bet-collapse-header-border: #e1e1e1;
  --time-table-event-border: #c9d1db;
  --single-league-border: #c9d1db;
  --filtered-element-border: #c9d1db;
  --live-game-row-border: #f1f1f1;
  --grid-template-border: #ececec;
  --hint-wrapper-border: #262626;
  --slider-item-bg-color: #fbfbfb;
  --odd-row-bg-color: #fff;
  --single-bet-sport-info-color: #000;
  --ant-collapse-header-color: #fdfdfd;
  --odd-row-color: #000;
  --bet-box-club-name-color: #000;
  --bet-box-close-btn: #ebeff4;
  --date-text-info-color: #000;
  --prediction-info-color: #000;
  --gaining-counter-hint: #000;
  --bet-type-handler-label: #000;
  --result-info-hint-color: #ebeff4;
  --bet-hint-color: #ebeff4;
  --navigator-hint-color: #ebeff4;
  --withdraw-deposit-color: #ebeff4;
  --leagues-helmet-color: #000;
  --single-league-color: #000;
  --fixture-container-color: #000;
  --result-counter-text: #000;
  --live-panel-header: #000;
  --participant-name-color: #000;
  --condtiotions-header-color: #ebeff4;
  --navigation-menu-title-color: #ebeff4;
  --verification-type-title-color: #ebeff4;
  --bet-history-header-title: #ebeff4;
  --bet-history-picker-color: #ebeff4;
  --client-info-color: #ebeff4;
  --mobile-personal-page-menu-color: #ebeff4;
  --bet-history-game-info-color: #ebeff4;
  --inbox-header-title: #ebeff4;
  --password-and-security-label-color: #ebeff4;
  --verification-form-color: #ebeff4;
  --single-league-wrapper-background-color: #fbfbfb;
  --searchPage-header-down-bg-color: #fff;
  --current-league-hint-background-color: #fff;
  --numpad-key-background-color: #fdfdfd;
  --bets-total-background-color: #fff;
  --sport-bet-box-info-bg-color: #fff;
  --gaining-counter-wrapper-bg-color: #fff;
  --bet-type-handler-button-bg-color: #fff;
  --result-info-wrapper-bg-color: #1c1c1e;
  --popup-betslip-wrapper-bg-color: #1c1c1e;
  --all-leagues-helmet-bg-color: #f9f9f9;
  --filtered-elements-page-header-wrapper-bg-color: #f9f9f9;
  --result-wrapper-bg-color: #f9f9f9;
  --live-game-row-wrapper-bg-color: #fdfdfd;
  --single-league-bets-content-box-bg-color: #fff;
  --nearby-games-bg-color: #1c1c1e;
  --modal-page-wrapper-bg-color: #1c1c1e;
  --bet-history-wrapper-header-bg-color: #1c1c1e;
  --bet-history-collapse-header-bg-color: #1c1c1e;
  --deposit-nested-wrapper-bg-color: #1c1c1e;
  --mobile-personal-details-menu-bg-color: #1c1c1e;
  --password-and-security-bg-color: #1c1c1e;
  --single-transaction-bg-color: #1c1c1e;
  --verification-page-bg-color: #1c1c1e;
  --betslip-tab-text-color: #3d3d3d;
  --multiple-bets-text-info-color: #000;
  --mobile-footer-links-color: #868686;
  --login-field-placeholder-color: #cacaca;
  --balance-wrapper-text-title: #979797;
  --header-down-route-color: #fff;
  --custom-label-sportcount-color: #fff;
  --ant-drawer-bg-color: #f1f1f1;
  --popup-submit-button-color: #fff;
  --login-field-hint-color: #132a3a;
  --page-header-title-color: #000;
  --single-bet-coefficent-box-outline-color: #ececec;
  --coefficent-box-diff-color: #000;
  --bet-coefficent-template-hover-color: #fff;
  --ant-navbar-active-key-color: #000;
  --menu-item-hover-color: #fff;
  --current-hint-league-color: #000;
  --bet-history-collapse-wrapper-bg-color: #f8f8f8;
  --not-found-page-title-color: #000;
  --slider-item-border-color: #ececec;
  --rate-text-info-color: #ebeff4;
  --filter-shadow-color: rgba(0, 0, 0, 0.2);
  --filter-shadow-arg1: 0px;
  --filter-shadow-arg2: 1px;
  --filter-shadow-arg3: 2px;
  --coefficent-box-wrapper-suspended-color: #9b9b9b;
  --in-play-hint-text-color: #111;
}
[data-theme='dark'] {
  --live-icon-inactive-color: #64646f;
  --live-icon-inactive-bg-color: #2c2c2f;
  --live-menu-collapse-header-bg-color: #2a2a2e;
  --live-menu-collapse-header-color: #ffffff;
  --container-menu-color: #090909;
  --live-menu-collapse-item-bg-color: #1c1c1e;
  --live-menu-border-color: #39393b;
  --container-color: #090909;
  --background-color: #0c1d29;
  --sport-select-color: #112633;
  --header-color: #1c1c1e;
  --footer-text-color: #c7cbcf;
  --line-divider: #2a2a2e;
  --line-divider-footer: #1a1a1a;
  --header-down-text-color: #cacaca;
  --login-input-field-bg: #2a2a2a;
  --common-reverse-color: #fff;
  --ant-primary-button-text-color: #fff;
  --header-routes-color: #fff;
  --betslip-nested-routes: #0a0a0a;
  --odds-action-hint-text-color: #c7cbcf;
  --betslip-container-color: #080808;
  --betslip-amount-counter-text: #cacaca;
  --betslip-login-hint-button-bg-color: #72777f;
  --login-popup-input-bg-color: #0a0a0a;
  --login-popup-bg-color: #1c1c1e;
  --login-popup-hint-text-color-regular: #c9d1db;
  --login-popup-hint-text-color-bold: #f0f6fe;
  --menu-sport-select-text-color: #efefef;
  --common-blue-color: #2b90ff;
  --single-bet-row-title: #adadad;
  --single-bet-header-background-color: #1c1c1e;
  --single-bet-header-border-color: #262626;
  --single-bet-coefficent-box-background-color: #000;
  --single-bet-row-background-color: #161616;
  --user-balance-dropdown-background-color: #fff;
  --user-bets-sider-menu-text-color: #ebeff4;
  --gradient-start-color: #1d1c1c;
  --unavailable-bet-box-wrapper-background-color: #232326;
  --single-bet-page-header-background-color: #1c1c1e;
  --svg-icon-color-sider-menu: #ebeff4;
  --svg-icon-color-sider-menu-active: #a2e9ff;
  --svg-icon-swiper: #868686;
  --svg-icon-swiper-active: #ebeff4;
  --blue-color-multimode: #2b90ff;
  --hint-header-color: #2a2a2e;
  --text-area-background-color: #0d0d0d;
  --custom-numpad-key-bg-color: #040404;
  --custom-numpad-key-color: #ebeff4;
  --betslip-background-color: #101010;
  --drawer-body-color: #040404;
  --bets-wrapper-background-color: #000000;
  --betslip-handler-background-color: #000000;
  --sider-menu-background-color: #000;
  --case-component-background-color: #1c1c1e;
  --betslip-header-tabs-background-color: #1c1c1e;
  --events-swiper-background-color: #1c1c1e;
  --slider-button-background-color: #1c1c1e;
  --slider-item-sportname-color: #ebeff4;
  --footer-background-color: #161616;
  --match-counter-background-color: #2a2a2e;
  --navigation-links-background-color: #1c1c1e;
  --grid-template-background-color: #1c1c1e;
  --nearby-games-hint-background-color: #1c1c1e;
  --game-location-background-color: #1c1c1e;
  --user-balance-background-color: #2a2a2e;
  --deposit-select-background-color: #2a2a2a;
  --bet-box-header-background-color: #1c1c1e;
  --login-hint-button-message-color: #fff;
  --popup-header-color: #fff;
  --popup-input-color: #fff;
  --popup-select-color: #fff;
  --checkbox-label-color: #fff;
  --coefficent-color: #fff;
  --sport-rule-text-area: #fff;
  --verification-box-row-wrapper: #fff;
  --bet-type-picker-selection-color: #fff;
  --inbox-input-color: #fff;
  --reset-password-color: #fff;
  --input-row-field-color: #fff;
  --balance-color: #fff;
  --sport-betting-test-color: #fff;
  --acount-status-color: #fff;
  --odds-handle-panel-background-color: #060606;
  --footer-privacy-policy-color: #cacaca;
  --header-login-color: #cacaca;
  --searchbar-input-color: #cacaca;
  --login-popup-select-bg-color: #0a0a0a;
  --inbox-content-input-bg-color: #0a0a0a;
  --reset-password-input-bg-color: #0a0a0a;
  --input-row-field-input-bg-color: #0a0a0a;
  --input-row-field-select-bg-color: #0a0a0a;
  --rules-search-input-bg-color: #161616;
  --rules-search-input-color: #161616;
  --deposit-address-hint-color: #c9d1db;
  --qr-code-hint-color: #c9d1db;
  --live-overview-match-counter: #c9d1db;
  --betting-side-header-title-color: #efefef;
  --single-league-bets-collapse-header-color: #efefef;
  --case-component-collapse-header-title-color: #868686;
  --special-fixture-cutoff-color: #868686;
  --prediction-info-indicator-color: #aeaeae;
  --result-info-wrapper-hint-color: #868686;
  --bet-history-connection-type: #868686;
  --bet-history-id-hint: #868686;
  --footer-selector-label-color: #868686;
  --footer-select-color: #868686;
  --mobile-footer-gambling-hint-color: #868686;
  --mobile-footer-privacy-policy-color: #868686;
  --ant-tab--btn-color: #868686;
  --searched-component-date-color: #868686;
  --live-game-timer-hint-color: #868686;
  --live-overview-header-title: #adadad;
  --single-bet-row-container-color: #868686;
  --single-bet-row-growth-number: #868686;
  --single-bet-markets-counter: #868686;
  --time-table-event-date-color: #868686;
  --account-status-hint-wrapper: #868686;
  --client-banner-clientId-color: #868686;
  --client-banner-balance-color: #868686;
  --bet-history-info-thin: #868686;
  --game-status-hint: #868686;
  --bet-type-picker-select-border-color: #868686;
  --inbox-header-date-color: #868686;
  --verification-page-ant-form-req-color: #868686;
  --bet-history-header-bg-color: #1c1c1e;
  --filtered-element-bg-color: #1c1c1e;
  --bet-history-bg-picker-color: #1c1c1e;
  --user-bets-hint-header-bg-color: #1c1c1e;
  --client-banner-bg-color: #1c1c1e;
  --client-manager-bg-color: #1c1c1e;
  --deposit-page-wrapper-div: #2a2a2e;
  --bet-type-picker-select-bg-color: #1c1c1e;
  --verification-form-bg-color: #1c1c1e;
  --bet-collapse-header-border: #262626;
  --time-table-event-border: #262626;
  --single-league-border: #262626;
  --filtered-element-border: #262626;
  --live-game-row-border: #202020;
  --grid-template-border: #2a2a2e;
  --hint-wrapper-border: #262626;
  --slider-item-bg-color: #2a2a2e;
  --odd-row-bg-color: #040404;
  --single-bet-sport-info-color: #fff;
  --ant-collapse-header-color: #1c1c1e;
  --odd-row-color: #fff;
  --bet-box-club-name-color: #ebeff4;
  --bet-box-close-btn: #ebeff4;
  --date-text-info-color: #ebeff4;
  --prediction-info-color: #ebeff4;
  --gaining-counter-hint: #ebeff4;
  --bet-type-handler-label: #ebeff4;
  --result-info-hint-color: #ebeff4;
  --bet-hint-color: #ebeff4;
  --navigator-hint-color: #ebeff4;
  --withdraw-deposit-color: #ebeff4;
  --leagues-helmet-color: #ebeff4;
  --single-league-color: #ebeff4;
  --fixture-container-color: #ebeff4;
  --result-counter-text: #ebeff4;
  --live-panel-header: #ebeff4;
  --participant-name-color: #ebeff4;
  --condtiotions-header-color: #ebeff4;
  --navigation-menu-title-color: #ebeff4;
  --verification-type-title-color: #ebeff4;
  --bet-history-header-title: #ebeff4;
  --bet-history-picker-color: #ebeff4;
  --client-info-color: #ebeff4;
  --mobile-personal-page-menu-color: #ebeff4;
  --bet-history-game-info-color: #ebeff4;
  --inbox-header-title: #ebeff4;
  --password-and-security-label-color: #ebeff4;
  --verification-form-color: #ebeff4;
  --single-league-wrapper-background-color: #1c1c1e;
  --searchPage-header-down-bg-color: #1c1c1e;
  --current-league-hint-background-color: #1c1c1e;
  --numpad-key-background-color: #1c1c1e;
  --bets-total-background-color: #1c1c1e;
  --sport-bet-box-info-bg-color: #1c1c1e;
  --gaining-counter-wrapper-bg-color: #1c1c1e;
  --bet-type-handler-button-bg-color: #1c1c1e;
  --result-info-wrapper-bg-color: #1c1c1e;
  --popup-betslip-wrapper-bg-color: #1c1c1e;
  --all-leagues-helmet-bg-color: #1c1c1e;
  --filtered-elements-page-header-wrapper-bg-color: #1c1c1e;
  --result-wrapper-bg-color: #1c1c1e;
  --live-game-row-wrapper-bg-color: #1c1c1e;
  --single-league-bets-content-box-bg-color: #1c1c1e;
  --nearby-games-bg-color: #1c1c1e;
  --modal-page-wrapper-bg-color: #1c1c1e;
  --bet-history-wrapper-header-bg-color: #1c1c1e;
  --bet-history-collapse-header-bg-color: #1c1c1e;
  --deposit-nested-wrapper-bg-color: #1c1c1e;
  --mobile-personal-details-menu-bg-color: #1c1c1e;
  --password-and-security-bg-color: #1c1c1e;
  --single-transaction-bg-color: #1c1c1e;
  --verification-page-bg-color: #1c1c1e;
  --betslip-tab-text-color: #3d3d3d;
  --multiple-bets-text-info-color: #ebeff4;
  --mobile-footer-links-color: #868686;
  --login-field-placeholder-color: #cacaca;
  --balance-wrapper-text-title: #979797;
  --header-down-route-color: #fff;
  --custom-label-sportcount-color: #fff;
  --ant-drawer-bg-color: #1c1c1e;
  --popup-submit-button-color: #fff;
  --login-field-hint-color: #132a3a;
  --page-header-title-color: #ebeff4;
  --single-bet-coefficent-box-outline-color: transparent;
  --coefficent-box-diff-color: #fff;
  --bet-coefficent-template-hover-color: #1f1f20;
  --ant-navbar-active-key-color: #fff;
  --menu-item-hover-color: #27272a;
  --current-hint-league-color: #ebeff4;
  --bet-history-collapse-wrapper-bg-color: #060606;
  --not-found-page-title-color: #fff;
  --slider-item-border-color: #242424;
  --rate-text-info-color: #ebeff4;
  --filter-shadow-color: transparent;
  --filter-shadow-arg1: 0px;
  --filter-shadow-arg2: 0px;
  --filter-shadow-arg3: 0px;
  --coefficent-box-wrapper-suspended-color: #72777f;
  --in-play-hint-text-color: #ebeff4;
  --popup-input-border-color: #2b2a2a;
}
.footer {
  width: 100%;
}
.footer__head {
  border-bottom: 1px solid var(--line-divider-footer);
  border-top: 1px solid var(--line-divider-footer);
  display: flex;
  gap: 20px;
  justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
}
.footer__head_hint {
  color: var(--footer-text-color);
  cursor: pointer;
  font-weight: 600;
  font-family: 'Satoshi';
  font-size: 11px;
  font-style: normal;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: none;
}
.gaming-warning {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 8px;
  margin-top: 10px;
  gap: 5px;
}
.gaming-warning__hint {
  color: #c7cbcf;
  color: var(--footer-text-color);
  font-family: 'Satoshi';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.horizontal-divider-line {
  background-color: var(--line-divider-footer);
  height: 1px;
  width: calc(100% + 50px);
}
.privacy-policy {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0 auto 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  align-content: center;
  width: fit-content;
}
.privacy-policy__info {
  color: #cacaca;
  color: var(--footer-privacy-policy-color);
  display: flex;
  font-family: 'Satoshi';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  justify-content: center;
  line-height: 12px;
}
.footer-images-wrapper,
.mobile-footer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.mobile-footer {
  border-radius: 2px;
  flex-direction: column;
  padding: 4px;
}
.mobile-footer .handler-links-wrapper {
  align-items: center;
  display: flex;
}
.mobile-footer .footter-selector-label {
  color: var(--footer-selector-label-color);
  font-family: 'Satoshi';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  height: 30px;
  line-height: 31px;
}
.mobile-footer .page-modes-handler {
  display: flex;
  min-width: 52;
}
.mobile-footer .mode-handlers-wrapper,
.mobile-footer .page-mode-handler-label-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.mobile-footer .mode-handlers-wrapper .ant-select-selection-item,
.mobile-footer .page-mode-handler-label-wrapper .ant-select-selection-item {
  color: var(--blue-color-multimode) !important;
}
.mobile-footer .mode-handlers-wrapper .ant-select,
.mobile-footer .page-mode-handler-label-wrapper .ant-select {
  width: clamp(105px, 26.5vw, 116px) !important;
}
.mobile-footer .ant-select,
.mobile-footer .ant-select-selector {
  height: 30px;
}
.mobile-footer .ant-select-selection-item {
  font-family: 'Satoshi';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  height: 30px;
  line-height: 29px !important;
  width: 100px;
}
.mobile-footer .ant-select-focused .ant-select-selection-item,
.mobile-footer .ant-select-selection-item {
  color: #868686;
  color: var(--footer-select-color);
}
.mobile-footer .footer-links-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  min-width: 38%;
  margin-left: 7px;
}
.mobile-footer .mobile-footer-links {
  color: #868686;
  color: var(--mobile-footer-links-color);
  font-family: 'Satoshi';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none !important;
}
.mobile-footer .footer-vertical-divider {
  background: var(--line-divider) !important;
  height: 90% !important;
  margin: 0 5px !important;
}
.mobile-footer .footer-horizontal-divider {
  background-color: var(--line-divider-footer);
  width: 100%;
}
.mobile-footer .footer-horizontal-divider__small-gap {
  margin: 5px 0;
}
.mobile-footer .hint-logos-wrapper {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
.mobile-footer .footer-mobile-gambling-hints-wrapper {
  display: flex;
  flex-direction: column;
}
.mobile-footer .footer-mobile-gambling-hints-wrapper .mobile-footer-gambling-hint {
  color: #868686;
  color: var(--mobile-footer-gambling-hint-color);
  font-family: 'Satoshi';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.mobile-footer .mobile-footer-logos-wrapper {
  align-items: center;
  display: flex;
  gap: 7px;
}
.mobile-footer .mobile-footer-privacy-policy {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mobile-footer .mobile-footer-bitcoin-hint,
.mobile-footer .mobile-footer-privacy-policy-text-wrapper {
  align-items: center;
  display: flex;
  height: 20px;
  color: var(--mobile-footer-privacy-policy-color);
  font-family: 'Satoshi';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mobile-footer .mobile-footer-privacy-policy-text {
  color: #868686;
  color: var(--mobile-footer-privacy-policy-color);
  font-family: 'Satoshi';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media only screen and (max-width: 350px) {
  .page-mode-handler-label-wrapper {
    display: none !important;
  }
}
@media only screen and (max-width: 500px) {
  .footter-selector-label {
    align-items: center;
    display: flex;
    line-height: 15px !important;
  }
}
