.flex-end {
	justify-content: flex-end;
}
.live-statistics-table-wrapper .time-table-wrapper .live-game-data .ant-row .ant-col:first-child,
.live-statistics-table-wrapper .time-table-wrapper .live-game-data .live-game-data {
	color: #ffffff;
	font-family: Satoshi Variable;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}
.live-statistics-table-wrapper {
	background: linear-gradient(180deg, #1e2d1f, #2b4129);
	border-radius: 0 0 4px 4px;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 5px 10px 5px 15px;
	.ant-divider {
		width: 100%;
		height: 1px;
		margin: 4px 0;
		background: #ffffff0d;
	}
	.live-game-hint {
		width: fit-content;
		display: flex;
		gap: 10px;
		align-items: center;
		&__date {
			font-family: 'Satoshi Variable';
			font-style: normal;
			font-weight: 700;
			font-size: 10px;
			line-height: 105%;
			color: #ebeff4;
			&_red {
				color: #ff4747;
			}
		}
	}
	.time-table-wrapper {
		display: flex;
		flex-direction: row;
		gap: 10px;
		font-family: 'Satoshi Variable';
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 15px;
		color: #d3d3d3;
		text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
		.time-tabel-header {
			width: 95%;
			.ant-col {
				font-family: 'Satoshi Variable';
				font-style: normal;
				font-weight: 500;
				font-size: 10px;
				line-height: 14px;
				leading-trim: both;
				text-edge: cap;
				color: #d3d3d3;
				text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
				padding: 2px 4px;
			}
		}
		.final-score {
			display: flex;
			gap: 9px;
			flex-direction: column;
			.ant-row {
				padding: 2px 6px;
				background: rgba(217, 217, 217, 0.14);
				border-radius: 2px;
				text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
				font-family: 'Satoshi Variable';
				font-style: normal;
				font-size: 11px;
				line-height: 15px;
				color: #ffffff;
			}
		}
		.live-game-data {
			width: 95%;
			display: flex;
			flex-direction: column;
			row-gap: 7px;
			.ant-col {
				font-family: 'Satoshi Variable';
				font-style: normal;
				font-weight: 700;
				font-size: 11px;
				line-height: 15px;
				color: #d3d3d3;
				text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);

				padding: 2px 4px;
			}
			.live-game-data,
			.ant-row .ant-col:first-child {
				font-family: 'Satoshi Variable';
				font-style: normal;
				font-weight: 700;
				font-size: 13px;
				line-height: 16px;
				leading-trim: both;
				text-edge: cap;
				color: white;
				text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
			}
		}
	}

	.time-table-menu {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__video {
			font-family: 'Satoshi Variable';
			font-style: normal;
			font-weight: 500;
			font-size: 11px;
			line-height: 15px;
			color: #ebeff4;
			text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);

			&_i {
				font-family: 'Oleo Script Swash Caps';
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 17px;
				color: #ffffff;
			}
		}
		&__buttons {
			display: flex;
			gap: 10px;
		}
		&__btn {
			cursor: pointer;
			background: #1e2d1f;
			border-radius: 2px;
			padding: 5px 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__active {
			background: rgba(255, 255, 255, 0.14);
		}
	}
	.game-statistics {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 170px;
		&__card {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 5px;
			margin: 1px;
			background: #d9d9d91a;
			border-radius: 2px;
			span {
				font-family: 'Satoshi Variable';
				font-style: normal;
				font-weight: 700;
				font-size: 11px;
				line-height: 15px;
				color: #ffffff;
				text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
			}
		}
	}
	.game-action {
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;
		flex-direction: column;
		max-height: 170px;
		&__card {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin: 2px;
			border-radius: 2px;
			&__team {
				display: flex;
				font-family: 'Satoshi Variable';
				font-style: normal;
				font-weight: 700;
				font-size: 11px;
				line-height: 15px;
				color: #d3d3d3;
				text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
				background: #d9d9d91a;
				border-radius: 2px;
				padding: 5px;
				width: 100%;
			}
			&__action-time {
				background: rgba(30, 45, 31, 0.51);
				border-radius: 2px;
				padding: 5px;
				font-family: 'Satoshi Variable';
				font-style: normal;
				font-weight: 700;
				font-size: 11px;
				line-height: 15px;
				color: #ffffff;
				text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
			}
		}
	}
}

:where(.css-12q6ue8).ant-row {
	display: flex;
	flex-flow: row wrap;
	min-width: 0;
	align-items: center;
}

@media only screen and (max-width: 500px) {
	.live-game-data,
	.time-tabel-header {
		width: 90% !important;
	}
}

@media only screen and (max-width: 800px) {
	.live-statistics-table-wrapper {
		.live-game-data,
		.ant-row .ant-col:first-child {
			width: 58.3%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

@primary-color: #0a0523;@secondary-color: #6C5DD3;@ui-red: #E53E3E;@ui-black: #111010;