@headerHeight: 77px;
@marginTopFromHeader: 7px;
@leftSiderWidth: 210px;
@betslipWidth: 300px;
@darkModeSwitchTransition: 0s;

@successTypeColor: #0d8f0e;
@loseTypeColor: #ff0000;
@unsettledTypeColor: #ffa800;

// media queries
@staticMarginLeft768: 10px;

:root {
	--coefficent-box-width: 60px;
}

@media screen and (max-width: 430px) {
	:root {
		--coefficent-box-width: 60px;
		/* New value for screens <= 768px */
	}
}

:root {
	--betslip-width: 300px;
	--left-sider-width: 196px;
	--numpad-height: 160px;
	--doc-height: 100%;
}

:root {
	--live-icon-inactive-color: #fff;
	--live-icon-inactive-bg-color: #9da0a6;
	--live-menu-collapse-header-bg-color: #ebebeb;
	--live-menu-collapse-header-color: #000000;
	--container-menu-color: #fdfdfd;

	--live-menu-collapse-item-bg-color: #fdfdfd;
	--live-menu-collapse-item-color: #000000;
	--live-menu-border-color: #c6c6c6;

	--container-color: #f2f2f2;
	--background-color: #0c1d29;
	--sport-select-color: #112633;
	--header-color: #1c1c1e;
	--footer-text-color: #606060;
	--line-divider: #ececec;
	--line-divider-dark: #252525;
	--line-divider-footer: #e2e2e2;
	--header-down-text-color: #cacaca;
	--login-input-field-bg: #2a2a2a;
	--common-reverse-color: #000;
	--ant-primary-button-text-color: #fff;
	--header-routes-color: #fff;
	--betslip-nested-routes: #0a0a0a;
	--odds-action-hint-text-color: #c7cbcf;
	--betslip-container-color: #f1f2f4;
	--betslip-amount-counter-text: #000;
	--betslip-login-hint-button-bg-color: #72777f;
	--login-popup-input-bg-color: #fbfbfb;
	--login-popup-bg-color: #1c1c1e;
	--login-popup-hint-text-color-regular: #c9d1db;
	--login-popup-hint-text-color-bold: #f0f6fe;
	--menu-sport-select-text-color: #efefef;
	--common-blue-color: #005fc8;
	--single-bet-row-title: #6d6d6d;
	--single-bet-header-background-color: #1c1c1e;
	--single-bet-header-border-color: #262626;
	--single-bet-coefficent-box-background-color: #fff;
	--single-bet-row-background-color: #161616;
	--user-balance-dropdown-background-color: #fff;
	--user-bets-sider-menu-text-color: #000;
	--gradient-start-color: #1d1c1c;
	--unavailable-bet-box-wrapper-background-color: #efefef;
	--single-bet-page-header-background-color: #fdfdfd;
	--svg-icon-color-sider-menu: #070606;
	--svg-icon-color-sider-menu-active: #000;
	--svg-icon-swiper: #868686;
	--svg-icon-swiper-active: #ebeff4;
	--blue-color-multimode: #005fc8;
	--hint-header-color: #f9f9f9;
	--text-area-background-color: #0d0d0d;
	--custom-numpad-key-bg-color: #c9d1db;
	--custom-numpad-key-color: #000;
	--betslip-background-color: #f2f2f2;
	--drawer-body-color: #040404;
	--bets-wrapper-background-color: #f1f1f1;
	--betslip-handler-background-color: #f1f1f1;
	--sider-menu-background-color: #f2f2f2;
	--case-component-background-color: #fdfdfd;
	--betslip-header-tabs-background-color: #191919;
	--events-swiper-background-color: #fdfdfd;
	--slider-button-background-color: #fdfdfd;
	--slider-item-sportname-color: #000;
	--footer-background-color: #161616;
	--match-counter-background-color: #fff;
	--navigation-links-background-color: #fff;
	--grid-template-background-color: #fdfdfd;
	--nearby-games-hint-background-color: #fdfdfd;
	--game-location-background-color: #fdfdfd;
	--user-balance-background-color: #2a2a2e;
	--deposit-select-background-color: #2a2a2a;
	--bet-box-header-background-color: #fff;
	--login-hint-button-message-color: #fff;
	--popup-header-color: #fff;
	--popup-input-color: #fff;
	--popup-select-color: #fff;
	--checkbox-label-color: #fff;
	--coefficent-color: #fff;
	--sport-rule-text-area: #fff;
	--verification-box-row-wrapper: #fff;
	--bet-type-picker-selection-color: #fff;
	--inbox-input-color: #fff;
	--reset-password-color: #fff;
	--input-row-field-color: #fff;
	--balance-color: #fff;
	--sport-betting-test-color: #fff;
	--acount-status-color: #fff;
	--odds-handle-panel-background-color: #f6f5f5;
	--footer-privacy-policy-color: #cacaca;
	--header-login-color: #cacaca;
	--searchbar-input-color: #cacaca;
	--login-popup-select-bg-color: #0a0a0a;
	--inbox-content-input-bg-color: #0a0a0a;
	--reset-password-input-bg-color: #0a0a0a;
	--input-row-field-input-bg-color: #0a0a0a;
	--input-row-field-select-bg-color: #0a0a0a;
	--rules-search-input-bg-color: #161616;
	--rules-search-input-color: #161616;
	--deposit-address-hint-color: #c9d1db;
	--qr-code-hint-color: #c9d1db;
	--live-overview-match-counter: #000;
	--betting-side-header-title-color: #efefef;
	--single-league-bets-collapse-header-color: #000;
	--case-component-collapse-header-title-color: #515151;
	--special-fixture-cutoff-color: #868686;
	--prediction-info-indicator-color: #474747;
	--result-info-wrapper-hint-color: #868686;
	--bet-history-connection-type: #868686;
	--bet-history-id-hint: #868686;
	--footer-selector-label-color: #868686;
	--footer-select-color: #868686;
	--mobile-footer-gambling-hint-color: #868686;
	--mobile-footer-privacy-policy-color: #868686;
	--ant-tab--btn-color: #868686;
	--searched-component-date-color: #868686;
	--live-game-timer-hint-color: #868686;
	--live-overview-header-title: #6d6d6d;
	--single-bet-row-container-color: #868686;
	--single-bet-row-growth-number: #868686;
	--single-bet-markets-counter: #868686;
	--time-table-event-date-color: #868686;
	--account-status-hint-wrapper: #868686;
	--client-banner-clientId-color: #868686;
	--client-banner-balance-color: #868686;
	--bet-history-info-thin: #868686;
	--game-status-hint: #868686;
	--bet-type-picker-select-border-color: #868686;
	--inbox-header-date-color: #868686;
	--verification-page-ant-form-req-color: #868686;
	--bet-history-header-bg-color: #1c1c1e;
	--filtered-element-bg-color: #fdfdfd;
	--bet-history-bg-picker-color: #1c1c1e;
	--user-bets-hint-header-bg-color: #1c1c1e;
	--client-banner-bg-color: #1c1c1e;
	--client-manager-bg-color: #1c1c1e;
	--deposit-page-wrapper-div: #2a2a2e;
	--bet-type-picker-select-bg-color: #1c1c1e;
	--password-and-security-bg-color: #1c1c1e;
	--verification-form-bg-color: #1c1c1e;
	--bet-collapse-header-border: #e1e1e1;
	--time-table-event-border: #c9d1db;
	--single-league-border: #c9d1db;
	--filtered-element-border: #c9d1db;
	--live-game-row-border: #f1f1f1;
	--grid-template-border: #ececec;
	--hint-wrapper-border: #262626;
	--slider-item-bg-color: #fbfbfb;
	--odd-row-bg-color: #fff;
	--single-bet-sport-info-color: #000;
	--ant-collapse-header-color: #fdfdfd;
	--odd-row-color: #000;
	--bet-box-club-name-color: #000;
	--bet-box-close-btn: #ebeff4;
	--date-text-info-color: #000;
	--prediction-info-color: #000;
	--gaining-counter-hint: #000;
	--bet-type-handler-label: #000;
	--result-info-hint-color: #ebeff4;
	--bet-hint-color: #ebeff4;
	--navigator-hint-color: #ebeff4;
	--withdraw-deposit-color: #ebeff4;
	--leagues-helmet-color: #000;
	--single-league-color: #000;
	--fixture-container-color: #000;
	--result-counter-text: #000;
	--live-panel-header: #000;
	--participant-name-color: #000;
	--condtiotions-header-color: #ebeff4;
	--navigation-menu-title-color: #ebeff4;
	--verification-type-title-color: #ebeff4;
	--bet-history-header-title: #ebeff4;
	--bet-history-picker-color: #ebeff4;
	--client-info-color: #ebeff4;
	--mobile-personal-page-menu-color: #ebeff4;
	--bet-history-game-info-color: #ebeff4;
	--inbox-header-title: #ebeff4;
	--password-and-security-label-color: #ebeff4;
	--verification-form-color: #ebeff4;
	--single-league-wrapper-background-color: #fbfbfb;
	--searchPage-header-down-bg-color: #fff;
	--current-league-hint-background-color: #fff;
	--numpad-key-background-color: #fdfdfd;
	--bets-total-background-color: #fff;
	--sport-bet-box-info-bg-color: #fff;
	--gaining-counter-wrapper-bg-color: #fff;
	--bet-type-handler-button-bg-color: #fff;
	--result-info-wrapper-bg-color: #1c1c1e;
	--popup-betslip-wrapper-bg-color: #1c1c1e;
	--all-leagues-helmet-bg-color: #f9f9f9;
	--filtered-elements-page-header-wrapper-bg-color: #f9f9f9;
	--result-wrapper-bg-color: #f9f9f9;
	--live-game-row-wrapper-bg-color: #fdfdfd;
	--single-league-bets-content-box-bg-color: #fff;
	--nearby-games-bg-color: #1c1c1e;
	--modal-page-wrapper-bg-color: #1c1c1e;
	--bet-history-wrapper-header-bg-color: #1c1c1e;
	--bet-history-collapse-header-bg-color: #1c1c1e;
	--deposit-nested-wrapper-bg-color: #1c1c1e;
	--mobile-personal-details-menu-bg-color: #1c1c1e;
	--password-and-security-bg-color: #1c1c1e;
	--single-transaction-bg-color: #1c1c1e;
	--verification-page-bg-color: #1c1c1e;
	--betslip-tab-text-color: #3d3d3d;
	--multiple-bets-text-info-color: #000;
	--mobile-footer-links-color: #868686;
	--login-field-placeholder-color: #cacaca;
	--balance-wrapper-text-title: #979797;
	--header-down-route-color: #fff;
	--custom-label-sportcount-color: #fff;
	--ant-drawer-bg-color: #f1f1f1;
	--popup-submit-button-color: #fff;
	--login-field-hint-color: #132a3a;
	--page-header-title-color: #000;
	--single-bet-coefficent-box-outline-color: #ececec;
	--coefficent-box-diff-color: #000;
	--bet-coefficent-template-hover-color: #fff;
	--ant-navbar-active-key-color: #000;
	--menu-item-hover-color: #fff;
	--current-hint-league-color: #000;
	--bet-history-collapse-wrapper-bg-color: #f8f8f8;
	--not-found-page-title-color: #000;
	--slider-item-border-color: #ececec;
	--rate-text-info-color: #ebeff4;
	--filter-shadow-color: rgba(0, 0, 0, 0.2);
	--filter-shadow-arg1: 0px;
	--filter-shadow-arg2: 1px;
	--filter-shadow-arg3: 2px;
	--coefficent-box-wrapper-suspended-color: #9b9b9b;
	--in-play-hint-text-color: #111;
}

[data-theme='dark'] {
	--live-icon-inactive-color: #64646f;
	--live-icon-inactive-bg-color: #2c2c2f;
	--live-menu-collapse-header-bg-color: #2a2a2e;
	--live-menu-collapse-header-color: #ffffff;
	--container-menu-color: #090909;
	--live-menu-collapse-item-bg-color: #1c1c1e;

	--live-menu-border-color: #39393b;

	--container-color: #090909;
	--background-color: #0c1d29;
	--sport-select-color: #112633;
	--header-color: #1c1c1e;
	--footer-text-color: #c7cbcf;
	--line-divider: #2a2a2e;
	--line-divider-footer: #1a1a1a;
	--header-down-text-color: #cacaca;
	--login-input-field-bg: #2a2a2a;
	--common-reverse-color: #fff;
	--ant-primary-button-text-color: #fff;
	--header-routes-color: #fff;
	--betslip-nested-routes: #0a0a0a;
	--odds-action-hint-text-color: #c7cbcf;
	--betslip-container-color: #080808;
	--betslip-amount-counter-text: #cacaca;
	--betslip-login-hint-button-bg-color: #72777f;
	--login-popup-input-bg-color: #0a0a0a;
	--login-popup-bg-color: #1c1c1e;
	--login-popup-hint-text-color-regular: #c9d1db;
	--login-popup-hint-text-color-bold: #f0f6fe;
	--menu-sport-select-text-color: #efefef;
	--common-blue-color: #2b90ff;
	--single-bet-row-title: #adadad;
	--single-bet-header-background-color: #1c1c1e;
	--single-bet-header-border-color: #262626;
	--single-bet-coefficent-box-background-color: #000;
	--single-bet-row-background-color: #161616;
	--user-balance-dropdown-background-color: #fff;
	--user-bets-sider-menu-text-color: #ebeff4;
	--gradient-start-color: #1d1c1c;
	--unavailable-bet-box-wrapper-background-color: #232326;
	--single-bet-page-header-background-color: #1c1c1e;
	--svg-icon-color-sider-menu: #ebeff4;
	--svg-icon-color-sider-menu-active: #a2e9ff;
	--svg-icon-swiper: #868686;
	--svg-icon-swiper-active: #ebeff4;
	--blue-color-multimode: #2b90ff;
	--hint-header-color: #2a2a2e;
	--text-area-background-color: #0d0d0d;
	--custom-numpad-key-bg-color: #040404;
	--custom-numpad-key-color: #ebeff4;
	--betslip-background-color: #101010;
	--drawer-body-color: #040404;
	--bets-wrapper-background-color: #000000;
	--betslip-handler-background-color: #000000;
	--sider-menu-background-color: #000;
	--case-component-background-color: #1c1c1e;
	--betslip-header-tabs-background-color: #1c1c1e;
	--events-swiper-background-color: #1c1c1e;
	--slider-button-background-color: #1c1c1e;
	--slider-item-sportname-color: #ebeff4;
	--footer-background-color: #161616;
	--match-counter-background-color: #2a2a2e;
	--navigation-links-background-color: #1c1c1e;
	--grid-template-background-color: #1c1c1e;
	--nearby-games-hint-background-color: #1c1c1e;
	--game-location-background-color: #1c1c1e;
	--user-balance-background-color: #2a2a2e;
	--deposit-select-background-color: #2a2a2a;
	--bet-box-header-background-color: #1c1c1e;
	--login-hint-button-message-color: #fff;
	--popup-header-color: #fff;
	--popup-input-color: #fff;
	--popup-select-color: #fff;
	--checkbox-label-color: #fff;
	--coefficent-color: #fff;
	--sport-rule-text-area: #fff;
	--verification-box-row-wrapper: #fff;
	--bet-type-picker-selection-color: #fff;
	--inbox-input-color: #fff;
	--reset-password-color: #fff;
	--input-row-field-color: #fff;
	--balance-color: #fff;
	--sport-betting-test-color: #fff;
	--acount-status-color: #fff;
	--odds-handle-panel-background-color: #060606;
	--footer-privacy-policy-color: #cacaca;
	--header-login-color: #cacaca;
	--searchbar-input-color: #cacaca;
	--login-popup-select-bg-color: #0a0a0a;
	--inbox-content-input-bg-color: #0a0a0a;
	--reset-password-input-bg-color: #0a0a0a;
	--input-row-field-input-bg-color: #0a0a0a;
	--input-row-field-select-bg-color: #0a0a0a;
	--rules-search-input-bg-color: #161616;
	--rules-search-input-color: #161616;
	--deposit-address-hint-color: #c9d1db;
	--qr-code-hint-color: #c9d1db;
	--live-overview-match-counter: #c9d1db;
	--betting-side-header-title-color: #efefef;
	--single-league-bets-collapse-header-color: #efefef;
	--case-component-collapse-header-title-color: #868686;
	--special-fixture-cutoff-color: #868686;
	--prediction-info-indicator-color: #aeaeae;
	--result-info-wrapper-hint-color: #868686;
	--bet-history-connection-type: #868686;
	--bet-history-id-hint: #868686;
	--footer-selector-label-color: #868686;
	--footer-select-color: #868686;
	--mobile-footer-gambling-hint-color: #868686;
	--mobile-footer-privacy-policy-color: #868686;
	--ant-tab--btn-color: #868686;
	--searched-component-date-color: #868686;
	--live-game-timer-hint-color: #868686;
	--live-overview-header-title: #adadad;
	--single-bet-row-container-color: #868686;
	--single-bet-row-growth-number: #868686;
	--single-bet-markets-counter: #868686;
	--time-table-event-date-color: #868686;
	--account-status-hint-wrapper: #868686;
	--client-banner-clientId-color: #868686;
	--client-banner-balance-color: #868686;
	--bet-history-info-thin: #868686;
	--game-status-hint: #868686;
	--bet-type-picker-select-border-color: #868686;
	--inbox-header-date-color: #868686;
	--verification-page-ant-form-req-color: #868686;
	--bet-history-header-bg-color: #1c1c1e;
	--filtered-element-bg-color: #1c1c1e;
	--bet-history-bg-picker-color: #1c1c1e;
	--user-bets-hint-header-bg-color: #1c1c1e;
	--client-banner-bg-color: #1c1c1e;
	--client-manager-bg-color: #1c1c1e;
	--deposit-page-wrapper-div: #2a2a2e;
	--bet-type-picker-select-bg-color: #1c1c1e;
	--password-and-security-bg-color: #1c1c1e;
	--verification-form-bg-color: #1c1c1e;
	--bet-collapse-header-border: #262626;
	--time-table-event-border: #262626;
	--single-league-border: #262626;
	--filtered-element-border: #262626;
	--live-game-row-border: #202020;
	--grid-template-border: #2a2a2e;
	--hint-wrapper-border: #262626;
	--slider-item-bg-color: #2a2a2e;
	--odd-row-bg-color: #040404;
	--single-bet-sport-info-color: #fff;
	--ant-collapse-header-color: #1c1c1e;
	--odd-row-color: #fff;
	--bet-box-club-name-color: #ebeff4;
	--bet-box-close-btn: #ebeff4;
	--date-text-info-color: #ebeff4;
	--prediction-info-color: #ebeff4;
	--gaining-counter-hint: #ebeff4;
	--bet-type-handler-label: #ebeff4;
	--result-info-hint-color: #ebeff4;
	--bet-hint-color: #ebeff4;
	--navigator-hint-color: #ebeff4;
	--withdraw-deposit-color: #ebeff4;
	--leagues-helmet-color: #ebeff4;
	--single-league-color: #ebeff4;
	--fixture-container-color: #ebeff4;
	--result-counter-text: #ebeff4;
	--live-panel-header: #ebeff4;
	--participant-name-color: #ebeff4;
	--condtiotions-header-color: #ebeff4;
	--navigation-menu-title-color: #ebeff4;
	--verification-type-title-color: #ebeff4;
	--bet-history-header-title: #ebeff4;
	--bet-history-picker-color: #ebeff4;
	--client-info-color: #ebeff4;
	--mobile-personal-page-menu-color: #ebeff4;
	--bet-history-game-info-color: #ebeff4;
	--inbox-header-title: #ebeff4;
	--password-and-security-label-color: #ebeff4;
	--verification-form-color: #ebeff4;
	--single-league-wrapper-background-color: #1c1c1e;
	--searchPage-header-down-bg-color: #1c1c1e;
	--current-league-hint-background-color: #1c1c1e;
	--numpad-key-background-color: #1c1c1e;
	--bets-total-background-color: #1c1c1e;
	--sport-bet-box-info-bg-color: #1c1c1e;
	--gaining-counter-wrapper-bg-color: #1c1c1e;
	--bet-type-handler-button-bg-color: #1c1c1e;
	--result-info-wrapper-bg-color: #1c1c1e;
	--popup-betslip-wrapper-bg-color: #1c1c1e;
	--all-leagues-helmet-bg-color: #1c1c1e;
	--filtered-elements-page-header-wrapper-bg-color: #1c1c1e;
	--result-wrapper-bg-color: #1c1c1e;
	--live-game-row-wrapper-bg-color: #1c1c1e;
	--single-league-bets-content-box-bg-color: #1c1c1e;
	--nearby-games-bg-color: #1c1c1e;
	--modal-page-wrapper-bg-color: #1c1c1e;
	--bet-history-wrapper-header-bg-color: #1c1c1e;
	--bet-history-collapse-header-bg-color: #1c1c1e;
	--deposit-nested-wrapper-bg-color: #1c1c1e;
	--mobile-personal-details-menu-bg-color: #1c1c1e;
	--password-and-security-bg-color: #1c1c1e;
	--single-transaction-bg-color: #1c1c1e;
	--verification-page-bg-color: #1c1c1e;
	--betslip-tab-text-color: #3d3d3d;
	--multiple-bets-text-info-color: #ebeff4;
	--mobile-footer-links-color: #868686;
	--login-field-placeholder-color: #cacaca;
	--balance-wrapper-text-title: #979797;
	--header-down-route-color: #fff;
	--custom-label-sportcount-color: #fff;
	--ant-drawer-bg-color: #1c1c1e;
	--popup-submit-button-color: #fff;
	--login-field-hint-color: #132a3a;
	--page-header-title-color: #ebeff4;
	--single-bet-coefficent-box-outline-color: transparent;
	--coefficent-box-diff-color: #fff;
	--bet-coefficent-template-hover-color: #1f1f20;
	--ant-navbar-active-key-color: #fff;
	--menu-item-hover-color: #27272a;
	--current-hint-league-color: #ebeff4;
	--bet-history-collapse-wrapper-bg-color: #060606;
	--not-found-page-title-color: #fff;
	--slider-item-border-color: #242424;
	--rate-text-info-color: #ebeff4;
	--filter-shadow-color: transparent;
	--filter-shadow-arg1: 0px;
	--filter-shadow-arg2: 0px;
	--filter-shadow-arg3: 0px;
	--coefficent-box-wrapper-suspended-color: #72777f;
	--in-play-hint-text-color: #ebeff4;
	--popup-input-border-color: #2b2a2a;
}
