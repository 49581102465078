:root {
  --coefficent-box-width: 60px;
}
@media screen and (max-width: 430px) {
  :root {
    --coefficent-box-width: 60px;
    /* New value for screens <= 768px */
  }
}
:root {
  --betslip-width: 300px;
  --left-sider-width: 196px;
  --numpad-height: 160px;
  --doc-height: 100%;
}
:root {
  --live-icon-inactive-color: #fff;
  --live-icon-inactive-bg-color: #9da0a6;
  --live-menu-collapse-header-bg-color: #ebebeb;
  --live-menu-collapse-header-color: #000000;
  --container-menu-color: #fdfdfd;
  --live-menu-collapse-item-bg-color: #fdfdfd;
  --live-menu-collapse-item-color: #000000;
  --live-menu-border-color: #c6c6c6;
  --container-color: #f2f2f2;
  --background-color: #0c1d29;
  --sport-select-color: #112633;
  --header-color: #1c1c1e;
  --footer-text-color: #606060;
  --line-divider: #ececec;
  --line-divider-dark: #252525;
  --line-divider-footer: #e2e2e2;
  --header-down-text-color: #cacaca;
  --login-input-field-bg: #2a2a2a;
  --common-reverse-color: #000;
  --ant-primary-button-text-color: #fff;
  --header-routes-color: #fff;
  --betslip-nested-routes: #0a0a0a;
  --odds-action-hint-text-color: #c7cbcf;
  --betslip-container-color: #f1f2f4;
  --betslip-amount-counter-text: #000;
  --betslip-login-hint-button-bg-color: #72777f;
  --login-popup-input-bg-color: #fbfbfb;
  --login-popup-bg-color: #1c1c1e;
  --login-popup-hint-text-color-regular: #c9d1db;
  --login-popup-hint-text-color-bold: #f0f6fe;
  --menu-sport-select-text-color: #efefef;
  --common-blue-color: #005fc8;
  --single-bet-row-title: #6d6d6d;
  --single-bet-header-background-color: #1c1c1e;
  --single-bet-header-border-color: #262626;
  --single-bet-coefficent-box-background-color: #fff;
  --single-bet-row-background-color: #161616;
  --user-balance-dropdown-background-color: #fff;
  --user-bets-sider-menu-text-color: #000;
  --gradient-start-color: #1d1c1c;
  --unavailable-bet-box-wrapper-background-color: #efefef;
  --single-bet-page-header-background-color: #fdfdfd;
  --svg-icon-color-sider-menu: #070606;
  --svg-icon-color-sider-menu-active: #000;
  --svg-icon-swiper: #868686;
  --svg-icon-swiper-active: #ebeff4;
  --blue-color-multimode: #005fc8;
  --hint-header-color: #f9f9f9;
  --text-area-background-color: #0d0d0d;
  --custom-numpad-key-bg-color: #c9d1db;
  --custom-numpad-key-color: #000;
  --betslip-background-color: #f2f2f2;
  --drawer-body-color: #040404;
  --bets-wrapper-background-color: #f1f1f1;
  --betslip-handler-background-color: #f1f1f1;
  --sider-menu-background-color: #f2f2f2;
  --case-component-background-color: #fdfdfd;
  --betslip-header-tabs-background-color: #191919;
  --events-swiper-background-color: #fdfdfd;
  --slider-button-background-color: #fdfdfd;
  --slider-item-sportname-color: #000;
  --footer-background-color: #161616;
  --match-counter-background-color: #fff;
  --navigation-links-background-color: #fff;
  --grid-template-background-color: #fdfdfd;
  --nearby-games-hint-background-color: #fdfdfd;
  --game-location-background-color: #fdfdfd;
  --user-balance-background-color: #2a2a2e;
  --deposit-select-background-color: #2a2a2a;
  --bet-box-header-background-color: #fff;
  --login-hint-button-message-color: #fff;
  --popup-header-color: #fff;
  --popup-input-color: #fff;
  --popup-select-color: #fff;
  --checkbox-label-color: #fff;
  --coefficent-color: #fff;
  --sport-rule-text-area: #fff;
  --verification-box-row-wrapper: #fff;
  --bet-type-picker-selection-color: #fff;
  --inbox-input-color: #fff;
  --reset-password-color: #fff;
  --input-row-field-color: #fff;
  --balance-color: #fff;
  --sport-betting-test-color: #fff;
  --acount-status-color: #fff;
  --odds-handle-panel-background-color: #f6f5f5;
  --footer-privacy-policy-color: #cacaca;
  --header-login-color: #cacaca;
  --searchbar-input-color: #cacaca;
  --login-popup-select-bg-color: #0a0a0a;
  --inbox-content-input-bg-color: #0a0a0a;
  --reset-password-input-bg-color: #0a0a0a;
  --input-row-field-input-bg-color: #0a0a0a;
  --input-row-field-select-bg-color: #0a0a0a;
  --rules-search-input-bg-color: #161616;
  --rules-search-input-color: #161616;
  --deposit-address-hint-color: #c9d1db;
  --qr-code-hint-color: #c9d1db;
  --live-overview-match-counter: #000;
  --betting-side-header-title-color: #efefef;
  --single-league-bets-collapse-header-color: #000;
  --case-component-collapse-header-title-color: #515151;
  --special-fixture-cutoff-color: #868686;
  --prediction-info-indicator-color: #474747;
  --result-info-wrapper-hint-color: #868686;
  --bet-history-connection-type: #868686;
  --bet-history-id-hint: #868686;
  --footer-selector-label-color: #868686;
  --footer-select-color: #868686;
  --mobile-footer-gambling-hint-color: #868686;
  --mobile-footer-privacy-policy-color: #868686;
  --ant-tab--btn-color: #868686;
  --searched-component-date-color: #868686;
  --live-game-timer-hint-color: #868686;
  --live-overview-header-title: #6d6d6d;
  --single-bet-row-container-color: #868686;
  --single-bet-row-growth-number: #868686;
  --single-bet-markets-counter: #868686;
  --time-table-event-date-color: #868686;
  --account-status-hint-wrapper: #868686;
  --client-banner-clientId-color: #868686;
  --client-banner-balance-color: #868686;
  --bet-history-info-thin: #868686;
  --game-status-hint: #868686;
  --bet-type-picker-select-border-color: #868686;
  --inbox-header-date-color: #868686;
  --verification-page-ant-form-req-color: #868686;
  --bet-history-header-bg-color: #1c1c1e;
  --filtered-element-bg-color: #fdfdfd;
  --bet-history-bg-picker-color: #1c1c1e;
  --user-bets-hint-header-bg-color: #1c1c1e;
  --client-banner-bg-color: #1c1c1e;
  --client-manager-bg-color: #1c1c1e;
  --deposit-page-wrapper-div: #2a2a2e;
  --bet-type-picker-select-bg-color: #1c1c1e;
  --verification-form-bg-color: #1c1c1e;
  --bet-collapse-header-border: #e1e1e1;
  --time-table-event-border: #c9d1db;
  --single-league-border: #c9d1db;
  --filtered-element-border: #c9d1db;
  --live-game-row-border: #f1f1f1;
  --grid-template-border: #ececec;
  --hint-wrapper-border: #262626;
  --slider-item-bg-color: #fbfbfb;
  --odd-row-bg-color: #fff;
  --single-bet-sport-info-color: #000;
  --ant-collapse-header-color: #fdfdfd;
  --odd-row-color: #000;
  --bet-box-club-name-color: #000;
  --bet-box-close-btn: #ebeff4;
  --date-text-info-color: #000;
  --prediction-info-color: #000;
  --gaining-counter-hint: #000;
  --bet-type-handler-label: #000;
  --result-info-hint-color: #ebeff4;
  --bet-hint-color: #ebeff4;
  --navigator-hint-color: #ebeff4;
  --withdraw-deposit-color: #ebeff4;
  --leagues-helmet-color: #000;
  --single-league-color: #000;
  --fixture-container-color: #000;
  --result-counter-text: #000;
  --live-panel-header: #000;
  --participant-name-color: #000;
  --condtiotions-header-color: #ebeff4;
  --navigation-menu-title-color: #ebeff4;
  --verification-type-title-color: #ebeff4;
  --bet-history-header-title: #ebeff4;
  --bet-history-picker-color: #ebeff4;
  --client-info-color: #ebeff4;
  --mobile-personal-page-menu-color: #ebeff4;
  --bet-history-game-info-color: #ebeff4;
  --inbox-header-title: #ebeff4;
  --password-and-security-label-color: #ebeff4;
  --verification-form-color: #ebeff4;
  --single-league-wrapper-background-color: #fbfbfb;
  --searchPage-header-down-bg-color: #fff;
  --current-league-hint-background-color: #fff;
  --numpad-key-background-color: #fdfdfd;
  --bets-total-background-color: #fff;
  --sport-bet-box-info-bg-color: #fff;
  --gaining-counter-wrapper-bg-color: #fff;
  --bet-type-handler-button-bg-color: #fff;
  --result-info-wrapper-bg-color: #1c1c1e;
  --popup-betslip-wrapper-bg-color: #1c1c1e;
  --all-leagues-helmet-bg-color: #f9f9f9;
  --filtered-elements-page-header-wrapper-bg-color: #f9f9f9;
  --result-wrapper-bg-color: #f9f9f9;
  --live-game-row-wrapper-bg-color: #fdfdfd;
  --single-league-bets-content-box-bg-color: #fff;
  --nearby-games-bg-color: #1c1c1e;
  --modal-page-wrapper-bg-color: #1c1c1e;
  --bet-history-wrapper-header-bg-color: #1c1c1e;
  --bet-history-collapse-header-bg-color: #1c1c1e;
  --deposit-nested-wrapper-bg-color: #1c1c1e;
  --mobile-personal-details-menu-bg-color: #1c1c1e;
  --password-and-security-bg-color: #1c1c1e;
  --single-transaction-bg-color: #1c1c1e;
  --verification-page-bg-color: #1c1c1e;
  --betslip-tab-text-color: #3d3d3d;
  --multiple-bets-text-info-color: #000;
  --mobile-footer-links-color: #868686;
  --login-field-placeholder-color: #cacaca;
  --balance-wrapper-text-title: #979797;
  --header-down-route-color: #fff;
  --custom-label-sportcount-color: #fff;
  --ant-drawer-bg-color: #f1f1f1;
  --popup-submit-button-color: #fff;
  --login-field-hint-color: #132a3a;
  --page-header-title-color: #000;
  --single-bet-coefficent-box-outline-color: #ececec;
  --coefficent-box-diff-color: #000;
  --bet-coefficent-template-hover-color: #fff;
  --ant-navbar-active-key-color: #000;
  --menu-item-hover-color: #fff;
  --current-hint-league-color: #000;
  --bet-history-collapse-wrapper-bg-color: #f8f8f8;
  --not-found-page-title-color: #000;
  --slider-item-border-color: #ececec;
  --rate-text-info-color: #ebeff4;
  --filter-shadow-color: rgba(0, 0, 0, 0.2);
  --filter-shadow-arg1: 0px;
  --filter-shadow-arg2: 1px;
  --filter-shadow-arg3: 2px;
  --coefficent-box-wrapper-suspended-color: #9b9b9b;
  --in-play-hint-text-color: #111;
}
[data-theme='dark'] {
  --live-icon-inactive-color: #64646f;
  --live-icon-inactive-bg-color: #2c2c2f;
  --live-menu-collapse-header-bg-color: #2a2a2e;
  --live-menu-collapse-header-color: #ffffff;
  --container-menu-color: #090909;
  --live-menu-collapse-item-bg-color: #1c1c1e;
  --live-menu-border-color: #39393b;
  --container-color: #090909;
  --background-color: #0c1d29;
  --sport-select-color: #112633;
  --header-color: #1c1c1e;
  --footer-text-color: #c7cbcf;
  --line-divider: #2a2a2e;
  --line-divider-footer: #1a1a1a;
  --header-down-text-color: #cacaca;
  --login-input-field-bg: #2a2a2a;
  --common-reverse-color: #fff;
  --ant-primary-button-text-color: #fff;
  --header-routes-color: #fff;
  --betslip-nested-routes: #0a0a0a;
  --odds-action-hint-text-color: #c7cbcf;
  --betslip-container-color: #080808;
  --betslip-amount-counter-text: #cacaca;
  --betslip-login-hint-button-bg-color: #72777f;
  --login-popup-input-bg-color: #0a0a0a;
  --login-popup-bg-color: #1c1c1e;
  --login-popup-hint-text-color-regular: #c9d1db;
  --login-popup-hint-text-color-bold: #f0f6fe;
  --menu-sport-select-text-color: #efefef;
  --common-blue-color: #2b90ff;
  --single-bet-row-title: #adadad;
  --single-bet-header-background-color: #1c1c1e;
  --single-bet-header-border-color: #262626;
  --single-bet-coefficent-box-background-color: #000;
  --single-bet-row-background-color: #161616;
  --user-balance-dropdown-background-color: #fff;
  --user-bets-sider-menu-text-color: #ebeff4;
  --gradient-start-color: #1d1c1c;
  --unavailable-bet-box-wrapper-background-color: #232326;
  --single-bet-page-header-background-color: #1c1c1e;
  --svg-icon-color-sider-menu: #ebeff4;
  --svg-icon-color-sider-menu-active: #a2e9ff;
  --svg-icon-swiper: #868686;
  --svg-icon-swiper-active: #ebeff4;
  --blue-color-multimode: #2b90ff;
  --hint-header-color: #2a2a2e;
  --text-area-background-color: #0d0d0d;
  --custom-numpad-key-bg-color: #040404;
  --custom-numpad-key-color: #ebeff4;
  --betslip-background-color: #101010;
  --drawer-body-color: #040404;
  --bets-wrapper-background-color: #000000;
  --betslip-handler-background-color: #000000;
  --sider-menu-background-color: #000;
  --case-component-background-color: #1c1c1e;
  --betslip-header-tabs-background-color: #1c1c1e;
  --events-swiper-background-color: #1c1c1e;
  --slider-button-background-color: #1c1c1e;
  --slider-item-sportname-color: #ebeff4;
  --footer-background-color: #161616;
  --match-counter-background-color: #2a2a2e;
  --navigation-links-background-color: #1c1c1e;
  --grid-template-background-color: #1c1c1e;
  --nearby-games-hint-background-color: #1c1c1e;
  --game-location-background-color: #1c1c1e;
  --user-balance-background-color: #2a2a2e;
  --deposit-select-background-color: #2a2a2a;
  --bet-box-header-background-color: #1c1c1e;
  --login-hint-button-message-color: #fff;
  --popup-header-color: #fff;
  --popup-input-color: #fff;
  --popup-select-color: #fff;
  --checkbox-label-color: #fff;
  --coefficent-color: #fff;
  --sport-rule-text-area: #fff;
  --verification-box-row-wrapper: #fff;
  --bet-type-picker-selection-color: #fff;
  --inbox-input-color: #fff;
  --reset-password-color: #fff;
  --input-row-field-color: #fff;
  --balance-color: #fff;
  --sport-betting-test-color: #fff;
  --acount-status-color: #fff;
  --odds-handle-panel-background-color: #060606;
  --footer-privacy-policy-color: #cacaca;
  --header-login-color: #cacaca;
  --searchbar-input-color: #cacaca;
  --login-popup-select-bg-color: #0a0a0a;
  --inbox-content-input-bg-color: #0a0a0a;
  --reset-password-input-bg-color: #0a0a0a;
  --input-row-field-input-bg-color: #0a0a0a;
  --input-row-field-select-bg-color: #0a0a0a;
  --rules-search-input-bg-color: #161616;
  --rules-search-input-color: #161616;
  --deposit-address-hint-color: #c9d1db;
  --qr-code-hint-color: #c9d1db;
  --live-overview-match-counter: #c9d1db;
  --betting-side-header-title-color: #efefef;
  --single-league-bets-collapse-header-color: #efefef;
  --case-component-collapse-header-title-color: #868686;
  --special-fixture-cutoff-color: #868686;
  --prediction-info-indicator-color: #aeaeae;
  --result-info-wrapper-hint-color: #868686;
  --bet-history-connection-type: #868686;
  --bet-history-id-hint: #868686;
  --footer-selector-label-color: #868686;
  --footer-select-color: #868686;
  --mobile-footer-gambling-hint-color: #868686;
  --mobile-footer-privacy-policy-color: #868686;
  --ant-tab--btn-color: #868686;
  --searched-component-date-color: #868686;
  --live-game-timer-hint-color: #868686;
  --live-overview-header-title: #adadad;
  --single-bet-row-container-color: #868686;
  --single-bet-row-growth-number: #868686;
  --single-bet-markets-counter: #868686;
  --time-table-event-date-color: #868686;
  --account-status-hint-wrapper: #868686;
  --client-banner-clientId-color: #868686;
  --client-banner-balance-color: #868686;
  --bet-history-info-thin: #868686;
  --game-status-hint: #868686;
  --bet-type-picker-select-border-color: #868686;
  --inbox-header-date-color: #868686;
  --verification-page-ant-form-req-color: #868686;
  --bet-history-header-bg-color: #1c1c1e;
  --filtered-element-bg-color: #1c1c1e;
  --bet-history-bg-picker-color: #1c1c1e;
  --user-bets-hint-header-bg-color: #1c1c1e;
  --client-banner-bg-color: #1c1c1e;
  --client-manager-bg-color: #1c1c1e;
  --deposit-page-wrapper-div: #2a2a2e;
  --bet-type-picker-select-bg-color: #1c1c1e;
  --verification-form-bg-color: #1c1c1e;
  --bet-collapse-header-border: #262626;
  --time-table-event-border: #262626;
  --single-league-border: #262626;
  --filtered-element-border: #262626;
  --live-game-row-border: #202020;
  --grid-template-border: #2a2a2e;
  --hint-wrapper-border: #262626;
  --slider-item-bg-color: #2a2a2e;
  --odd-row-bg-color: #040404;
  --single-bet-sport-info-color: #fff;
  --ant-collapse-header-color: #1c1c1e;
  --odd-row-color: #fff;
  --bet-box-club-name-color: #ebeff4;
  --bet-box-close-btn: #ebeff4;
  --date-text-info-color: #ebeff4;
  --prediction-info-color: #ebeff4;
  --gaining-counter-hint: #ebeff4;
  --bet-type-handler-label: #ebeff4;
  --result-info-hint-color: #ebeff4;
  --bet-hint-color: #ebeff4;
  --navigator-hint-color: #ebeff4;
  --withdraw-deposit-color: #ebeff4;
  --leagues-helmet-color: #ebeff4;
  --single-league-color: #ebeff4;
  --fixture-container-color: #ebeff4;
  --result-counter-text: #ebeff4;
  --live-panel-header: #ebeff4;
  --participant-name-color: #ebeff4;
  --condtiotions-header-color: #ebeff4;
  --navigation-menu-title-color: #ebeff4;
  --verification-type-title-color: #ebeff4;
  --bet-history-header-title: #ebeff4;
  --bet-history-picker-color: #ebeff4;
  --client-info-color: #ebeff4;
  --mobile-personal-page-menu-color: #ebeff4;
  --bet-history-game-info-color: #ebeff4;
  --inbox-header-title: #ebeff4;
  --password-and-security-label-color: #ebeff4;
  --verification-form-color: #ebeff4;
  --single-league-wrapper-background-color: #1c1c1e;
  --searchPage-header-down-bg-color: #1c1c1e;
  --current-league-hint-background-color: #1c1c1e;
  --numpad-key-background-color: #1c1c1e;
  --bets-total-background-color: #1c1c1e;
  --sport-bet-box-info-bg-color: #1c1c1e;
  --gaining-counter-wrapper-bg-color: #1c1c1e;
  --bet-type-handler-button-bg-color: #1c1c1e;
  --result-info-wrapper-bg-color: #1c1c1e;
  --popup-betslip-wrapper-bg-color: #1c1c1e;
  --all-leagues-helmet-bg-color: #1c1c1e;
  --filtered-elements-page-header-wrapper-bg-color: #1c1c1e;
  --result-wrapper-bg-color: #1c1c1e;
  --live-game-row-wrapper-bg-color: #1c1c1e;
  --single-league-bets-content-box-bg-color: #1c1c1e;
  --nearby-games-bg-color: #1c1c1e;
  --modal-page-wrapper-bg-color: #1c1c1e;
  --bet-history-wrapper-header-bg-color: #1c1c1e;
  --bet-history-collapse-header-bg-color: #1c1c1e;
  --deposit-nested-wrapper-bg-color: #1c1c1e;
  --mobile-personal-details-menu-bg-color: #1c1c1e;
  --password-and-security-bg-color: #1c1c1e;
  --single-transaction-bg-color: #1c1c1e;
  --verification-page-bg-color: #1c1c1e;
  --betslip-tab-text-color: #3d3d3d;
  --multiple-bets-text-info-color: #ebeff4;
  --mobile-footer-links-color: #868686;
  --login-field-placeholder-color: #cacaca;
  --balance-wrapper-text-title: #979797;
  --header-down-route-color: #fff;
  --custom-label-sportcount-color: #fff;
  --ant-drawer-bg-color: #1c1c1e;
  --popup-submit-button-color: #fff;
  --login-field-hint-color: #132a3a;
  --page-header-title-color: #ebeff4;
  --single-bet-coefficent-box-outline-color: transparent;
  --coefficent-box-diff-color: #fff;
  --bet-coefficent-template-hover-color: #1f1f20;
  --ant-navbar-active-key-color: #fff;
  --menu-item-hover-color: #27272a;
  --current-hint-league-color: #ebeff4;
  --bet-history-collapse-wrapper-bg-color: #060606;
  --not-found-page-title-color: #fff;
  --slider-item-border-color: #242424;
  --rate-text-info-color: #ebeff4;
  --filter-shadow-color: transparent;
  --filter-shadow-arg1: 0px;
  --filter-shadow-arg2: 0px;
  --filter-shadow-arg3: 0px;
  --coefficent-box-wrapper-suspended-color: #72777f;
  --in-play-hint-text-color: #ebeff4;
  --popup-input-border-color: #2b2a2a;
}
:root {
  --coefficent-box-width: 60px;
}
@media screen and (max-width: 430px) {
  :root {
    --coefficent-box-width: 60px;
    /* New value for screens <= 768px */
  }
}
:root {
  --betslip-width: 300px;
  --left-sider-width: 196px;
  --numpad-height: 160px;
  --doc-height: 100%;
}
:root {
  --live-icon-inactive-color: #fff;
  --live-icon-inactive-bg-color: #9da0a6;
  --live-menu-collapse-header-bg-color: #ebebeb;
  --live-menu-collapse-header-color: #000000;
  --container-menu-color: #fdfdfd;
  --live-menu-collapse-item-bg-color: #fdfdfd;
  --live-menu-collapse-item-color: #000000;
  --live-menu-border-color: #c6c6c6;
  --container-color: #f2f2f2;
  --background-color: #0c1d29;
  --sport-select-color: #112633;
  --header-color: #1c1c1e;
  --footer-text-color: #606060;
  --line-divider: #ececec;
  --line-divider-dark: #252525;
  --line-divider-footer: #e2e2e2;
  --header-down-text-color: #cacaca;
  --login-input-field-bg: #2a2a2a;
  --common-reverse-color: #000;
  --ant-primary-button-text-color: #fff;
  --header-routes-color: #fff;
  --betslip-nested-routes: #0a0a0a;
  --odds-action-hint-text-color: #c7cbcf;
  --betslip-container-color: #f1f2f4;
  --betslip-amount-counter-text: #000;
  --betslip-login-hint-button-bg-color: #72777f;
  --login-popup-input-bg-color: #fbfbfb;
  --login-popup-bg-color: #1c1c1e;
  --login-popup-hint-text-color-regular: #c9d1db;
  --login-popup-hint-text-color-bold: #f0f6fe;
  --menu-sport-select-text-color: #efefef;
  --common-blue-color: #005fc8;
  --single-bet-row-title: #6d6d6d;
  --single-bet-header-background-color: #1c1c1e;
  --single-bet-header-border-color: #262626;
  --single-bet-coefficent-box-background-color: #fff;
  --single-bet-row-background-color: #161616;
  --user-balance-dropdown-background-color: #fff;
  --user-bets-sider-menu-text-color: #000;
  --gradient-start-color: #1d1c1c;
  --unavailable-bet-box-wrapper-background-color: #efefef;
  --single-bet-page-header-background-color: #fdfdfd;
  --svg-icon-color-sider-menu: #070606;
  --svg-icon-color-sider-menu-active: #000;
  --svg-icon-swiper: #868686;
  --svg-icon-swiper-active: #ebeff4;
  --blue-color-multimode: #005fc8;
  --hint-header-color: #f9f9f9;
  --text-area-background-color: #0d0d0d;
  --custom-numpad-key-bg-color: #c9d1db;
  --custom-numpad-key-color: #000;
  --betslip-background-color: #f2f2f2;
  --drawer-body-color: #040404;
  --bets-wrapper-background-color: #f1f1f1;
  --betslip-handler-background-color: #f1f1f1;
  --sider-menu-background-color: #f2f2f2;
  --case-component-background-color: #fdfdfd;
  --betslip-header-tabs-background-color: #191919;
  --events-swiper-background-color: #fdfdfd;
  --slider-button-background-color: #fdfdfd;
  --slider-item-sportname-color: #000;
  --footer-background-color: #161616;
  --match-counter-background-color: #fff;
  --navigation-links-background-color: #fff;
  --grid-template-background-color: #fdfdfd;
  --nearby-games-hint-background-color: #fdfdfd;
  --game-location-background-color: #fdfdfd;
  --user-balance-background-color: #2a2a2e;
  --deposit-select-background-color: #2a2a2a;
  --bet-box-header-background-color: #fff;
  --login-hint-button-message-color: #fff;
  --popup-header-color: #fff;
  --popup-input-color: #fff;
  --popup-select-color: #fff;
  --checkbox-label-color: #fff;
  --coefficent-color: #fff;
  --sport-rule-text-area: #fff;
  --verification-box-row-wrapper: #fff;
  --bet-type-picker-selection-color: #fff;
  --inbox-input-color: #fff;
  --reset-password-color: #fff;
  --input-row-field-color: #fff;
  --balance-color: #fff;
  --sport-betting-test-color: #fff;
  --acount-status-color: #fff;
  --odds-handle-panel-background-color: #f6f5f5;
  --footer-privacy-policy-color: #cacaca;
  --header-login-color: #cacaca;
  --searchbar-input-color: #cacaca;
  --login-popup-select-bg-color: #0a0a0a;
  --inbox-content-input-bg-color: #0a0a0a;
  --reset-password-input-bg-color: #0a0a0a;
  --input-row-field-input-bg-color: #0a0a0a;
  --input-row-field-select-bg-color: #0a0a0a;
  --rules-search-input-bg-color: #161616;
  --rules-search-input-color: #161616;
  --deposit-address-hint-color: #c9d1db;
  --qr-code-hint-color: #c9d1db;
  --live-overview-match-counter: #000;
  --betting-side-header-title-color: #efefef;
  --single-league-bets-collapse-header-color: #000;
  --case-component-collapse-header-title-color: #515151;
  --special-fixture-cutoff-color: #868686;
  --prediction-info-indicator-color: #474747;
  --result-info-wrapper-hint-color: #868686;
  --bet-history-connection-type: #868686;
  --bet-history-id-hint: #868686;
  --footer-selector-label-color: #868686;
  --footer-select-color: #868686;
  --mobile-footer-gambling-hint-color: #868686;
  --mobile-footer-privacy-policy-color: #868686;
  --ant-tab--btn-color: #868686;
  --searched-component-date-color: #868686;
  --live-game-timer-hint-color: #868686;
  --live-overview-header-title: #6d6d6d;
  --single-bet-row-container-color: #868686;
  --single-bet-row-growth-number: #868686;
  --single-bet-markets-counter: #868686;
  --time-table-event-date-color: #868686;
  --account-status-hint-wrapper: #868686;
  --client-banner-clientId-color: #868686;
  --client-banner-balance-color: #868686;
  --bet-history-info-thin: #868686;
  --game-status-hint: #868686;
  --bet-type-picker-select-border-color: #868686;
  --inbox-header-date-color: #868686;
  --verification-page-ant-form-req-color: #868686;
  --bet-history-header-bg-color: #1c1c1e;
  --filtered-element-bg-color: #fdfdfd;
  --bet-history-bg-picker-color: #1c1c1e;
  --user-bets-hint-header-bg-color: #1c1c1e;
  --client-banner-bg-color: #1c1c1e;
  --client-manager-bg-color: #1c1c1e;
  --deposit-page-wrapper-div: #2a2a2e;
  --bet-type-picker-select-bg-color: #1c1c1e;
  --verification-form-bg-color: #1c1c1e;
  --bet-collapse-header-border: #e1e1e1;
  --time-table-event-border: #c9d1db;
  --single-league-border: #c9d1db;
  --filtered-element-border: #c9d1db;
  --live-game-row-border: #f1f1f1;
  --grid-template-border: #ececec;
  --hint-wrapper-border: #262626;
  --slider-item-bg-color: #fbfbfb;
  --odd-row-bg-color: #fff;
  --single-bet-sport-info-color: #000;
  --ant-collapse-header-color: #fdfdfd;
  --odd-row-color: #000;
  --bet-box-club-name-color: #000;
  --bet-box-close-btn: #ebeff4;
  --date-text-info-color: #000;
  --prediction-info-color: #000;
  --gaining-counter-hint: #000;
  --bet-type-handler-label: #000;
  --result-info-hint-color: #ebeff4;
  --bet-hint-color: #ebeff4;
  --navigator-hint-color: #ebeff4;
  --withdraw-deposit-color: #ebeff4;
  --leagues-helmet-color: #000;
  --single-league-color: #000;
  --fixture-container-color: #000;
  --result-counter-text: #000;
  --live-panel-header: #000;
  --participant-name-color: #000;
  --condtiotions-header-color: #ebeff4;
  --navigation-menu-title-color: #ebeff4;
  --verification-type-title-color: #ebeff4;
  --bet-history-header-title: #ebeff4;
  --bet-history-picker-color: #ebeff4;
  --client-info-color: #ebeff4;
  --mobile-personal-page-menu-color: #ebeff4;
  --bet-history-game-info-color: #ebeff4;
  --inbox-header-title: #ebeff4;
  --password-and-security-label-color: #ebeff4;
  --verification-form-color: #ebeff4;
  --single-league-wrapper-background-color: #fbfbfb;
  --searchPage-header-down-bg-color: #fff;
  --current-league-hint-background-color: #fff;
  --numpad-key-background-color: #fdfdfd;
  --bets-total-background-color: #fff;
  --sport-bet-box-info-bg-color: #fff;
  --gaining-counter-wrapper-bg-color: #fff;
  --bet-type-handler-button-bg-color: #fff;
  --result-info-wrapper-bg-color: #1c1c1e;
  --popup-betslip-wrapper-bg-color: #1c1c1e;
  --all-leagues-helmet-bg-color: #f9f9f9;
  --filtered-elements-page-header-wrapper-bg-color: #f9f9f9;
  --result-wrapper-bg-color: #f9f9f9;
  --live-game-row-wrapper-bg-color: #fdfdfd;
  --single-league-bets-content-box-bg-color: #fff;
  --nearby-games-bg-color: #1c1c1e;
  --modal-page-wrapper-bg-color: #1c1c1e;
  --bet-history-wrapper-header-bg-color: #1c1c1e;
  --bet-history-collapse-header-bg-color: #1c1c1e;
  --deposit-nested-wrapper-bg-color: #1c1c1e;
  --mobile-personal-details-menu-bg-color: #1c1c1e;
  --password-and-security-bg-color: #1c1c1e;
  --single-transaction-bg-color: #1c1c1e;
  --verification-page-bg-color: #1c1c1e;
  --betslip-tab-text-color: #3d3d3d;
  --multiple-bets-text-info-color: #000;
  --mobile-footer-links-color: #868686;
  --login-field-placeholder-color: #cacaca;
  --balance-wrapper-text-title: #979797;
  --header-down-route-color: #fff;
  --custom-label-sportcount-color: #fff;
  --ant-drawer-bg-color: #f1f1f1;
  --popup-submit-button-color: #fff;
  --login-field-hint-color: #132a3a;
  --page-header-title-color: #000;
  --single-bet-coefficent-box-outline-color: #ececec;
  --coefficent-box-diff-color: #000;
  --bet-coefficent-template-hover-color: #fff;
  --ant-navbar-active-key-color: #000;
  --menu-item-hover-color: #fff;
  --current-hint-league-color: #000;
  --bet-history-collapse-wrapper-bg-color: #f8f8f8;
  --not-found-page-title-color: #000;
  --slider-item-border-color: #ececec;
  --rate-text-info-color: #ebeff4;
  --filter-shadow-color: rgba(0, 0, 0, 0.2);
  --filter-shadow-arg1: 0px;
  --filter-shadow-arg2: 1px;
  --filter-shadow-arg3: 2px;
  --coefficent-box-wrapper-suspended-color: #9b9b9b;
  --in-play-hint-text-color: #111;
}
[data-theme='dark'] {
  --live-icon-inactive-color: #64646f;
  --live-icon-inactive-bg-color: #2c2c2f;
  --live-menu-collapse-header-bg-color: #2a2a2e;
  --live-menu-collapse-header-color: #ffffff;
  --container-menu-color: #090909;
  --live-menu-collapse-item-bg-color: #1c1c1e;
  --live-menu-border-color: #39393b;
  --container-color: #090909;
  --background-color: #0c1d29;
  --sport-select-color: #112633;
  --header-color: #1c1c1e;
  --footer-text-color: #c7cbcf;
  --line-divider: #2a2a2e;
  --line-divider-footer: #1a1a1a;
  --header-down-text-color: #cacaca;
  --login-input-field-bg: #2a2a2a;
  --common-reverse-color: #fff;
  --ant-primary-button-text-color: #fff;
  --header-routes-color: #fff;
  --betslip-nested-routes: #0a0a0a;
  --odds-action-hint-text-color: #c7cbcf;
  --betslip-container-color: #080808;
  --betslip-amount-counter-text: #cacaca;
  --betslip-login-hint-button-bg-color: #72777f;
  --login-popup-input-bg-color: #0a0a0a;
  --login-popup-bg-color: #1c1c1e;
  --login-popup-hint-text-color-regular: #c9d1db;
  --login-popup-hint-text-color-bold: #f0f6fe;
  --menu-sport-select-text-color: #efefef;
  --common-blue-color: #2b90ff;
  --single-bet-row-title: #adadad;
  --single-bet-header-background-color: #1c1c1e;
  --single-bet-header-border-color: #262626;
  --single-bet-coefficent-box-background-color: #000;
  --single-bet-row-background-color: #161616;
  --user-balance-dropdown-background-color: #fff;
  --user-bets-sider-menu-text-color: #ebeff4;
  --gradient-start-color: #1d1c1c;
  --unavailable-bet-box-wrapper-background-color: #232326;
  --single-bet-page-header-background-color: #1c1c1e;
  --svg-icon-color-sider-menu: #ebeff4;
  --svg-icon-color-sider-menu-active: #a2e9ff;
  --svg-icon-swiper: #868686;
  --svg-icon-swiper-active: #ebeff4;
  --blue-color-multimode: #2b90ff;
  --hint-header-color: #2a2a2e;
  --text-area-background-color: #0d0d0d;
  --custom-numpad-key-bg-color: #040404;
  --custom-numpad-key-color: #ebeff4;
  --betslip-background-color: #101010;
  --drawer-body-color: #040404;
  --bets-wrapper-background-color: #000000;
  --betslip-handler-background-color: #000000;
  --sider-menu-background-color: #000;
  --case-component-background-color: #1c1c1e;
  --betslip-header-tabs-background-color: #1c1c1e;
  --events-swiper-background-color: #1c1c1e;
  --slider-button-background-color: #1c1c1e;
  --slider-item-sportname-color: #ebeff4;
  --footer-background-color: #161616;
  --match-counter-background-color: #2a2a2e;
  --navigation-links-background-color: #1c1c1e;
  --grid-template-background-color: #1c1c1e;
  --nearby-games-hint-background-color: #1c1c1e;
  --game-location-background-color: #1c1c1e;
  --user-balance-background-color: #2a2a2e;
  --deposit-select-background-color: #2a2a2a;
  --bet-box-header-background-color: #1c1c1e;
  --login-hint-button-message-color: #fff;
  --popup-header-color: #fff;
  --popup-input-color: #fff;
  --popup-select-color: #fff;
  --checkbox-label-color: #fff;
  --coefficent-color: #fff;
  --sport-rule-text-area: #fff;
  --verification-box-row-wrapper: #fff;
  --bet-type-picker-selection-color: #fff;
  --inbox-input-color: #fff;
  --reset-password-color: #fff;
  --input-row-field-color: #fff;
  --balance-color: #fff;
  --sport-betting-test-color: #fff;
  --acount-status-color: #fff;
  --odds-handle-panel-background-color: #060606;
  --footer-privacy-policy-color: #cacaca;
  --header-login-color: #cacaca;
  --searchbar-input-color: #cacaca;
  --login-popup-select-bg-color: #0a0a0a;
  --inbox-content-input-bg-color: #0a0a0a;
  --reset-password-input-bg-color: #0a0a0a;
  --input-row-field-input-bg-color: #0a0a0a;
  --input-row-field-select-bg-color: #0a0a0a;
  --rules-search-input-bg-color: #161616;
  --rules-search-input-color: #161616;
  --deposit-address-hint-color: #c9d1db;
  --qr-code-hint-color: #c9d1db;
  --live-overview-match-counter: #c9d1db;
  --betting-side-header-title-color: #efefef;
  --single-league-bets-collapse-header-color: #efefef;
  --case-component-collapse-header-title-color: #868686;
  --special-fixture-cutoff-color: #868686;
  --prediction-info-indicator-color: #aeaeae;
  --result-info-wrapper-hint-color: #868686;
  --bet-history-connection-type: #868686;
  --bet-history-id-hint: #868686;
  --footer-selector-label-color: #868686;
  --footer-select-color: #868686;
  --mobile-footer-gambling-hint-color: #868686;
  --mobile-footer-privacy-policy-color: #868686;
  --ant-tab--btn-color: #868686;
  --searched-component-date-color: #868686;
  --live-game-timer-hint-color: #868686;
  --live-overview-header-title: #adadad;
  --single-bet-row-container-color: #868686;
  --single-bet-row-growth-number: #868686;
  --single-bet-markets-counter: #868686;
  --time-table-event-date-color: #868686;
  --account-status-hint-wrapper: #868686;
  --client-banner-clientId-color: #868686;
  --client-banner-balance-color: #868686;
  --bet-history-info-thin: #868686;
  --game-status-hint: #868686;
  --bet-type-picker-select-border-color: #868686;
  --inbox-header-date-color: #868686;
  --verification-page-ant-form-req-color: #868686;
  --bet-history-header-bg-color: #1c1c1e;
  --filtered-element-bg-color: #1c1c1e;
  --bet-history-bg-picker-color: #1c1c1e;
  --user-bets-hint-header-bg-color: #1c1c1e;
  --client-banner-bg-color: #1c1c1e;
  --client-manager-bg-color: #1c1c1e;
  --deposit-page-wrapper-div: #2a2a2e;
  --bet-type-picker-select-bg-color: #1c1c1e;
  --verification-form-bg-color: #1c1c1e;
  --bet-collapse-header-border: #262626;
  --time-table-event-border: #262626;
  --single-league-border: #262626;
  --filtered-element-border: #262626;
  --live-game-row-border: #202020;
  --grid-template-border: #2a2a2e;
  --hint-wrapper-border: #262626;
  --slider-item-bg-color: #2a2a2e;
  --odd-row-bg-color: #040404;
  --single-bet-sport-info-color: #fff;
  --ant-collapse-header-color: #1c1c1e;
  --odd-row-color: #fff;
  --bet-box-club-name-color: #ebeff4;
  --bet-box-close-btn: #ebeff4;
  --date-text-info-color: #ebeff4;
  --prediction-info-color: #ebeff4;
  --gaining-counter-hint: #ebeff4;
  --bet-type-handler-label: #ebeff4;
  --result-info-hint-color: #ebeff4;
  --bet-hint-color: #ebeff4;
  --navigator-hint-color: #ebeff4;
  --withdraw-deposit-color: #ebeff4;
  --leagues-helmet-color: #ebeff4;
  --single-league-color: #ebeff4;
  --fixture-container-color: #ebeff4;
  --result-counter-text: #ebeff4;
  --live-panel-header: #ebeff4;
  --participant-name-color: #ebeff4;
  --condtiotions-header-color: #ebeff4;
  --navigation-menu-title-color: #ebeff4;
  --verification-type-title-color: #ebeff4;
  --bet-history-header-title: #ebeff4;
  --bet-history-picker-color: #ebeff4;
  --client-info-color: #ebeff4;
  --mobile-personal-page-menu-color: #ebeff4;
  --bet-history-game-info-color: #ebeff4;
  --inbox-header-title: #ebeff4;
  --password-and-security-label-color: #ebeff4;
  --verification-form-color: #ebeff4;
  --single-league-wrapper-background-color: #1c1c1e;
  --searchPage-header-down-bg-color: #1c1c1e;
  --current-league-hint-background-color: #1c1c1e;
  --numpad-key-background-color: #1c1c1e;
  --bets-total-background-color: #1c1c1e;
  --sport-bet-box-info-bg-color: #1c1c1e;
  --gaining-counter-wrapper-bg-color: #1c1c1e;
  --bet-type-handler-button-bg-color: #1c1c1e;
  --result-info-wrapper-bg-color: #1c1c1e;
  --popup-betslip-wrapper-bg-color: #1c1c1e;
  --all-leagues-helmet-bg-color: #1c1c1e;
  --filtered-elements-page-header-wrapper-bg-color: #1c1c1e;
  --result-wrapper-bg-color: #1c1c1e;
  --live-game-row-wrapper-bg-color: #1c1c1e;
  --single-league-bets-content-box-bg-color: #1c1c1e;
  --nearby-games-bg-color: #1c1c1e;
  --modal-page-wrapper-bg-color: #1c1c1e;
  --bet-history-wrapper-header-bg-color: #1c1c1e;
  --bet-history-collapse-header-bg-color: #1c1c1e;
  --deposit-nested-wrapper-bg-color: #1c1c1e;
  --mobile-personal-details-menu-bg-color: #1c1c1e;
  --password-and-security-bg-color: #1c1c1e;
  --single-transaction-bg-color: #1c1c1e;
  --verification-page-bg-color: #1c1c1e;
  --betslip-tab-text-color: #3d3d3d;
  --multiple-bets-text-info-color: #ebeff4;
  --mobile-footer-links-color: #868686;
  --login-field-placeholder-color: #cacaca;
  --balance-wrapper-text-title: #979797;
  --header-down-route-color: #fff;
  --custom-label-sportcount-color: #fff;
  --ant-drawer-bg-color: #1c1c1e;
  --popup-submit-button-color: #fff;
  --login-field-hint-color: #132a3a;
  --page-header-title-color: #ebeff4;
  --single-bet-coefficent-box-outline-color: transparent;
  --coefficent-box-diff-color: #fff;
  --bet-coefficent-template-hover-color: #1f1f20;
  --ant-navbar-active-key-color: #fff;
  --menu-item-hover-color: #27272a;
  --current-hint-league-color: #ebeff4;
  --bet-history-collapse-wrapper-bg-color: #060606;
  --not-found-page-title-color: #fff;
  --slider-item-border-color: #242424;
  --rate-text-info-color: #ebeff4;
  --filter-shadow-color: transparent;
  --filter-shadow-arg1: 0px;
  --filter-shadow-arg2: 0px;
  --filter-shadow-arg3: 0px;
  --coefficent-box-wrapper-suspended-color: #72777f;
  --in-play-hint-text-color: #ebeff4;
  --popup-input-border-color: #2b2a2a;
}
.container {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: var(--container-color);
}
.align-center {
  display: flex;
  align-items: center;
}
.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-input-border {
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
}
.popup-input-suffix-border {
  border: 2px solid #2b2a2a !important;
  border-radius: 2px;
}
.gradiental-border {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, var(--line-divider), #ffffff) 1;
}
.gradiental-border-darkmode {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to left, var(--line-divider), #524f4f) 1;
  border-top: none;
  border-left: none;
  border-right: none;
}
.ant-input-form-style .ant-input {
  background-color: var(--login-popup-input-bg-color);
  height: 32px;
  color: var(--common-reverse-color);
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px !important;
  display: flex !important;
  align-items: center !important;
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
}
.ant-input-form-style .ant-input::placeholder {
  color: var(--common-reverse-color);
}
.ant-tab-styles .ant-tabs-nav {
  margin: 0;
}
.ant-tab-styles .ant-tabs-nav::before {
  display: none !important;
}
.ant-tab-styles .ant-tabs-tab {
  margin: 0 !important;
  padding: 3px 5px !important;
}
.ant-tab-styles .ant-tabs-nav-list {
  gap: 10px;
}
.ant-tab-styles .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--ant-navbar-active-key-color) !important;
}
.ant-tab-styles .ant-tabs-tab-btn {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 11px !important;
  line-height: 15px;
  color: var(--ant-tab--btn-color);
  text-transform: uppercase;
}
.bet-box-header-styles {
  width: 100%;
  background: var(--sport-bet-box-info-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 2px 10px;
  border-radius: 4px 4px 0 0;
}
.gradiental-line-divider {
  width: 100%;
  height: 1px;
  background: linear-gradient(134deg, #262626 0%, rgba(38, 38, 38, 0) 100%);
}
.ant-input-prefix-styles .ant-input-number-affix-wrapper {
  width: 45%;
  background-color: var(--login-popup-input-bg-color);
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
}
.ant-input-prefix-styles .ant-input-number {
  background-color: transparent;
  display: flex;
  align-items: center;
}
.ant-input-prefix-styles .ant-input-number-input,
.ant-input-prefix-styles .ant-input-number-prefix {
  color: var(--common-reverse-color) !important;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px !important;
}
.ant-input-suffix-wrapper .ant-input-affix-wrapper {
  background-color: var(--login-popup-input-bg-color);
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
  padding: 0;
  padding-left: 5px;
}
.ant-input-suffix-wrapper .ant-input-affix-wrapper .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px red !important;
}
.ant-input-suffix-wrapper .ant-input {
  display: flex;
  align-items: center;
  border: none !important;
  padding-left: 5px;
}
.ant-input-suffix-wrapper .ant-input,
.ant-input-suffix-wrapper .ant-input-suffix {
  color: var(--common-reverse-color) !important;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px !important;
  margin-right: 5px;
}
.ant-input-suffix-wrapper .ant-input-suffix {
  cursor: pointer;
}
.ant-input-affix-styles .ant-input-affix-wrapper {
  width: 45%;
  background-color: var(--login-popup-input-bg-color);
  border: 1px solid var(--popup-input-border-color) !important;
  border-radius: 2px;
  padding: 0;
  padding-left: 5px;
  transition: none;
  height: 28px;
}
.ant-input-affix-styles .ant-input,
.ant-input-affix-styles .ant-input-prefix {
  color: var(--common-reverse-color) !important;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px !important;
  margin-right: 5px;
  background-color: var(--login-popup-input-bg-color);
  transition: none;
}
.ant-input-affix-styles .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px var(--common-blue-color) !important;
  border: none !important;
  outline: none;
}
.ant-input-affix-styles .ant-input-affix-wrapper-status-error {
  box-shadow: 0 0 0 2px #dc1226 !important;
  border: none !important;
}
.box-shadow-styles {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}
.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 86px;
  position: absolute;
  transition: 0s;
  margin-bottom: 7px;
  padding-top: 0;
  width: 100%;
  padding: 0 calc((100vw - 1280px) / 2) 0px;
  position: fixed;
  top: 0;
  background-color: var(--header-color);
  z-index: 100;
  box-shadow: 0 1px 7px black !important;
}
.header__up-part {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.header .login {
  width: fit-content;
  display: flex;
  flex-direction: row;
}
.header .login__input {
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  margin-right: 10px;
}
.header .login__input_field {
  width: 140px;
  height: 26px;
  padding: 5px;
  color: black;
  font-size: 11px !important;
  border-radius: 2px;
  background-color: var(--login-input-field-bg);
}
.header .login__input_field_darkmode {
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.7);
  border: 1px solid #484747;
  color: #c0c0c0 !important;
}
.header .login__input_field_lightmode {
  border: 1px solid #c0c0c0;
  border-radius: 2px;
}
.header .login__input_field::placeholder {
  color: var(--header-login-color);
}
.header .login__input_hint {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: start;
  color: var(--login-field-hint-color);
}
.header .login__input_hint_darkmode {
  color: #c7cbcf;
}
.header .login__input_hint-underline {
  text-decoration: underline;
  color: #000051;
  cursor: pointer;
}
.header .login__input_hint-underline_darkmode {
  color: #f9fbfd;
}
.header .login__input h1 {
  margin-top: 5px;
  font-size: 11px;
}
.header .login_field::placeholder {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--login-field-placeholder-color);
}
.header .mobile-header-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}
.header .header-full-page-gradient-divider {
  width: 100vw !important;
  margin: 0;
  background: linear-gradient(90deg, rgba(45, 47, 48, 0.1) 0.01%, #2d2f30 49.48%, rgba(45, 47, 48, 0.1) 99.99%);
  border-block-start: none !important;
  height: 1px;
}
.header .header-full-page-gradient-divider::before,
.header .header-full-page-gradient-divider::after {
  content: '';
  position: absolute;
}
.header .header-full-page-gradient-divider::before {
  left: 0;
}
.header .header-full-page-gradient-divider::after {
  right: 0;
  background: linear-gradient(to right, transparent, #252525);
}
.header .ant-select-selection-item {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 11px !important;
  line-height: 16px;
  text-align: right;
}
.header .ant-select-focused .ant-select-selection-item {
  color: var(--header-down-text-color);
}
.buttons-wrapper {
  height: fit-content;
  gap: 5px;
  display: flex;
}
.buttons-wrapper .ant-btn-default {
  background-color: initial;
  border-radius: 2px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  min-width: 60px;
  height: 26px;
  padding: 0 !important;
  color: var(--login-hint-button-message-color);
}
.buttons-wrapper .ant-btn-primary {
  background: var(--blue-color-multimode);
  border-radius: 2px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  min-width: 60px;
  height: 26px;
  padding: 0 !important;
  color: var(--ant-primary-button-text-color);
}
.header-logo {
  width: 120px;
  height: 50px;
  cursor: pointer;
}
.header-down {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 35px;
}
.header-down__search-routes-wrapper {
  display: flex;
  align-items: center;
}
.header-down .ant-select-selection-item {
  padding-inline-end: 5px !important;
}
.header-down__routes {
  display: flex;
  align-items: center;
  height: 100%;
}
.header-down__route {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27px;
  color: var(--header-routes-color);
}
.header-down__route_active {
  border-bottom: 2px solid var(--blue-color-multimode);
  margin-bottom: -2px;
}
.header-down__modes {
  display: flex;
}
.header-down .ant-input-affix-wrapper {
  border-radius: 3px;
  padding: 0px 20px 0 6px !important;
  width: 90%;
  font-size: 16px !important;
  background-color: #2a2a2e;
}
.header-down .ant-select-selector {
  padding: 0px 20px 0px 11px !important;
}
.dark-mode-handler {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dark-mode-handler__context {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  color: var(--header-down-text-color);
}
.ant-switch {
  border-radius: 25px;
  min-height: 15px;
  max-height: 15px;
  min-width: 27px;
  max-width: 27px;
}
.ant-switch.ant-switch-checked .ant-switch-handle,
.ant-switch-handle::before {
  inset-inline-start: calc(100% - 14px);
  width: 11px !important;
  height: 11px !important;
}
.ant-switch-inner {
  border-radius: 5px;
  width: 24px;
  height: 24px;
}
.ant-switch-checked .ant-switch-inner {
  transform: translateX(20px);
}
.ant-switch .ant-switch-handle::before {
  border-radius: 2px;
}
.header-down__modes .ant-space .ant-space-item .ant-select {
  width: fit-content !important;
}
.odds-type-selector {
  font-family: 'Satoshi', sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 11px !important;
  line-height: 16px;
  text-align: right;
  color: var(--header-down-text-color);
}
.timer-wrapper {
  align-items: center;
  display: flex;
  background-color: #2a2a2e;
  height: 22px;
  padding: 0px 7px;
  border-radius: 2px;
  margin-top: 5px;
  color: var(--header-down-text-color);
  font-family: monospace;
  font-size: 13px;
  line-height: 16px;
}
.searchbar {
  width: 220px;
}
.searchbar__input .ant-input {
  background: transparent !important;
  color: var(--searchbar-input-color) !important;
  font-size: 13px !important;
  line-height: 18px !important;
  padding: 0 !important;
}
.searchbar__input .ant-input::placeholder {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 17px;
  color: var(--searchbar-input-color) !important;
}
.searchbar__input:focus {
  border: none;
}
.search-icon {
  margin-right: 5px;
  width: 18px;
}
.timer-active {
  width: 70px;
}
.user-balance-wrapper {
  width: fit-content;
  padding: 0 5px;
  height: 34px;
  background-color: var(--user-balance-background-color);
  margin-top: 9px;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 10px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}
.user-balance-wrapper .vertical-line-divider {
  width: 1px;
  height: 70%;
  background-color: #979797;
}
.user-balance-wrapper .icons-wrapper {
  margin-top: 3px;
  display: flex;
  align-items: center;
  height: 90%;
  gap: 10px;
}
.user-balance-wrapper .balance-icon-wrapper {
  cursor: pointer;
}
.user-balance-wrapper .balance-icon {
  font-size: 20px;
}
.ant-badge-count {
  z-index: auto;
  min-width: 14px !important;
  height: 13px !important;
  color: #ffffff;
  font-size: 10px !important;
  font-family: 'Satoshi';
  font-weight: 700;
  line-height: 12px !important;
  white-space: nowrap;
  text-align: center;
  right: 2px !important;
  top: 4px !important;
  background: #dc1226 !important;
  border-radius: 2px !important;
  transition: background 0.2s;
  box-shadow: none !important;
}
.balance-amount {
  top: 5px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  line-height: 7px;
  text-align: right;
  cursor: pointer;
}
.balance-amount__sheet {
  gap: 7px;
  display: flex;
  align-items: center;
}
.balance-amount__sheet img {
  width: 18px;
  height: 18px;
}
.balance-amount__title {
  font-family: 'Satoshi';
  font-style: normal;
  font-size: 9px;
  color: var(--balance-wrapper-text-title);
}
.balance-amount__number {
  font-family: 'Satoshi';
  font-style: normal;
  font-size: 12px;
  color: var(--balance-color);
  margin-top: 1px;
}
.user-balance-dropdown .ant-dropdown-menu {
  background-color: var(--user-balance-dropdown-background-color);
  border-radius: 3px;
  padding: 4px;
}
.user-balance-dropdown .dropdown-settings {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, var(--line-divider), #ffffff) 1;
  border-bottom: none;
  border-left: none;
  border-right: none;
  padding: 2px 10px;
}
.user-balance-dropdown .custom-dropdown-label {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 2px 10px;
}
.user-balance-dropdown .custom-dropdown-label:hover {
  border-left: 2px solid var(--common-blue-color);
  margin-right: -2px;
}
.user-balance-dropdown .custom-dropdown-label__amount {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #111111;
}
.user-balance-dropdown .custom-dropdown-label .currency-wrapper {
  display: flex;
  gap: 5px;
}
.user-balance-dropdown .custom-dropdown-label .currency-wrapper__currencyy {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #111111;
}
.user-balance-dropdown .ant-dropdown-menu-item {
  padding: 0 !important;
}
.user-balance-dropdown .custom-dropdown-user-settings {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  color: #111111;
  padding: 2px 15px 2px 7px;
  display: flex;
  align-items: center;
}
.user-balance-dropdown .custom-dropdown-user-settings__bordered {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, var(--line-divider), #ffffff) 1;
  border-top: none;
  border-left: none;
  border-right: none;
}
.user-balance-dropdown__settings .ant-dropdown-arrow {
  right: 6px !important;
}
.user-balance-dropdown .custom-dropdown-user-settings:hover {
  margin-right: 0;
}
.user-login-header-form {
  display: flex;
  margin-top: 6px;
}
@media only screen and (max-width: 600px) {
  .user-login-header-form {
    display: flex;
    margin-right: 6px;
    margin-top: 0px;
    align-items: center;
  }
}
@media only screen and (max-width: 930px) {
  .timer-wrapper {
    display: none;
  }
}
.login-inputs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-right: 5px;
}
.user-login-header .ant-input {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  height: 26px;
  padding: 5px;
  color: var(--header-login-color);
  border-radius: 2px;
  background-color: var(--login-input-field-bg);
  width: 140px;
}
.user-login-header .ant-input::placeholder {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: var(--header-login-color);
  padding: 5px;
}
.user-login-header .ant-form-item-control-input {
  min-height: 0px !important;
}
.user-login-header .ant-form-item-explain-error {
  display: none;
}
.user-login-header .ant-form-item {
  margin-bottom: 0px !important;
}
.deposit-content-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
:where(.css-12q6ue8) .ant-form-item .ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 30px;
}
.popup-container .footer-language-selector .ant-select {
  width: 0px !important;
}
.popup-container .footer-language-selector .ant-select-selector {
  padding-left: 0 !important;
}
.hints-wrapper .forgot-password-hint {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: start;
  color: var(--header-login-color);
}
.hints-wrapper .forgot-password-hint__underline {
  color: var(--header-login-color);
  cursor: pointer;
}
.hints-wrapper .ant-form {
  line-height: 1px !important;
}
.sport-betting-test {
  font-family: 'Satoshi';
  font-style: normal;
  font-size: 13px !important;
  line-height: 18px;
  color: var(--sport-betting-test-color);
  margin-right: 10px;
}
.header-mobile {
  background-color: var(--header-color);
  height: 40px;
}
.mobile-header-title {
  color: var(--header-down-text-color);
  font-family: Satoshi;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mobile-header-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 1100px) {
  .header .ant-select-selection-item {
    font-size: 10px;
  }
  .header .timer-active {
    width: fit-content;
  }
  .header-down__route_active {
    margin-bottom: -2px !important;
  }
  .header-down__route {
    margin-right: 15px;
    font-size: 11px;
    line-height: 28px;
    color: var(--header-down-route-color);
    margin-bottom: 0;
  }
  .header-down__modes {
    display: flex;
  }
  .header-down-common-text {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1280px) {
  .header-down .ant-input-affix-wrapper {
    padding: 0 7px 0 7px !important;
    margin-left: 10px;
    width: 30px;
  }
  .header-down__routes {
    left: 170px;
  }
  .header {
    padding-right: 5px;
  }
}
@media only screen and (max-width: 800px) {
  .dark-mode-handler__context__route,
  .header-down__route {
    font-size: 11px;
    font-weight: 600;
    margin-right: 7px;
  }
  .dark-mode-handler__context__modes,
  .header-down__modes {
    display: flex;
  }
  .dark-mode-handler__context__routes,
  .header-down__routes {
    gap: 10px;
  }
  .user-login-input {
    width: 100px !important;
  }
  .header-logo {
    margin-left: 40px;
    width: 140px;
  }
}
@media only screen and (max-width: 1200px) {
  .header-logo {
    width: 110px !important;
    margin-left: 50px !important;
    margin-top: 1px;
  }
}
@media only screen and (max-width: 600px) {
  .mobile-betslip-opener {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px 8px;
    padding-right: 0;
    background-color: var(--blue-color-multimode);
    display: flex;
    align-items: center;
    height: 18px;
    border-radius: 3px;
    color: #fff;
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .mobile-betslip-opener .mobile-betslip-counter {
    background-color: #1880f7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    text-align: center;
  }
  .header-down__routes {
    position: relative;
    left: auto;
  }
  .header-down__route {
    white-space: nowrap;
    text-overflow: clip;
  }
  .searchbar {
    width: 55px;
    transition: 0.3s;
  }
  .searchbar__mobile-opened {
    width: 100vw;
    z-index: 999;
  }
  .searchbar__mobile-opened .ant-input-affix-wrapper {
    width: 95%;
    padding-right: 5px !important;
  }
  .searchbar__close-icon {
    display: flex;
    align-items: center;
  }
  .user-balance-wrapper {
    margin-top: 9px;
    margin-right: 6px;
  }
}
